import React, { useEffect } from 'react';
import { Button } from 'antd';
import './AdminLicenseHistory.css';
import { LicenseHistoryListComponent } from '../LicenseHistoryList';
import { getAllLicensesHistory } from '../../utils/api';

export function AdminLicenseHistoryComponent({
}) {

    const [ data, setData ] = React.useState([]);

    const getLicenseHistoryData = async () => {
        let res = await getAllLicensesHistory();
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getLicenseHistory", res);
            return res.data;
        } else {
            return [];
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const result = await getLicenseHistoryData();
            console.log("licenseHistory", result);
            setData(result);
        }


        fetchData().catch(error => {
            console.log("error", error);
        });
    }, []);


    return (
        <div>
            <div className="adminStatHeader">
                <div className="adminStatHeaderLabel">License History</div>
            </div>
            <div className="adminLicenseBodyBottom">
                <LicenseHistoryListComponent licenseHistoryList={data}></LicenseHistoryListComponent>
            </div>
        </div>
    )
}