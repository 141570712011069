import './App.css';
import { Switch, Route } from 'react-router-dom';
import { LandingPage, MenuPage, DashboardPage, AdminDashboardPage, MarketplacePage, SubscriptionPage, ImpressumPage, NotFoundPage, PrivacyPolicyPage, TermsPage, TermsCancellationPage } from './pages';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
//test key
//const stripePromise = loadStripe('pk_test_51MClqQADOJ6cXaEH35pMlHLbqn6pfb17uHRvko7Pvmr52nzaVvQ6Jdr48JdFTKUsLrHSHJc8zcg7dXjM0pkJnmfJ00nGF5KmqC');
//live key
const stripePromise = loadStripe('pk_live_51MClqQADOJ6cXaEHqxlNY9xa2B9tFwrxgWrRjP7s0zzbvuUBYnNVo2dAn3UrstchVvLIImUlRVrI2grklxYUMRE400Q9IKmrhU');

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route path="/" exact={true} component={LandingPage} />
        <Route path="/marketplace" exact={true} component={MarketplacePage} />
        <Route path="/dashboard" exact={true} component={DashboardPage} />
        <Route path="/dashboard/admin" exact={true} component={AdminDashboardPage} />
        <Route path="/dashboard/subscribe" exact={true} component={SubscriptionPage} />
        <Route path="/impressum" exact={true} component={ImpressumPage} />
        <Route path="/privacypolicy" exact={true} component={PrivacyPolicyPage} />
        <Route path="/cancellationpolicy" exact={true} component={TermsCancellationPage} />
        <Route path="/terms" exact={true} component={TermsPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Elements>
  );
}

export default App;
