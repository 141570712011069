import React from 'react';
import { Button } from 'antd';
import './ReleaseProductCard.css';
import axios from 'axios';
import { useEffect } from 'react';
import { getPlatformBackend } from '../../utils/platform';

export function ReleaseProductCardComponent({
    productData,
    customerNumberId,
    isShowPast,
    licenseId
}) {

    const [isShowDetails, setisShowDetails] = React.useState(false);
    const [checkoutAmount, setcheckoutAmount] = React.useState(0);
    const [moneySpentAmount, setmoneySpentAmount] = React.useState(0);
    //const [isShowPast, setisShowPast] = React.useState(false);

    const handleBtnClick = async () => {
        if (isShowDetails) {
            setisShowDetails(false);
        } else {
            //ATM
            try {
                await getPastProduct(customerNumberId, licenseId, productData.product_id)
            } catch (err) {
                console.log(err);
                setcheckoutAmount(0);
                setmoneySpentAmount(0);
            }

            setisShowDetails(true);
        }
    }

    const getPastProduct = async (customerId, license_id, productId) => {
        //console.log('orderComponentH orderStatisticsDetails', getPlatformBackend() + `/api/checkouts/all/user/${customerId}/product/${productId}`);
        // let orderStatisticsDetails = getOrderStatisticsDetails(dropdownDetailMapping[selectedRange], customerNumberId, selectedProductId);
        await axios.get(getPlatformBackend() + `/api/checkouts/overview/user/${customerId}/license/${license_id}/product/${productId}`, {
            withCredentials: true
        })
            .then((res) => {
                console.log('calendar product', res.data);
                setcheckoutAmount(res.data.checkouts);
                setmoneySpentAmount(res.data.moneySpent);
            }).catch((error) => {
                console.log('error', error);
                setcheckoutAmount(0);
                setmoneySpentAmount(0);
            });

        // console.log('orderComponentH orderStatisticsDetails', orderStatisticsDetails);
        // setorderDataDetails(orderStatisticsDetails);
    }


    //console.log('ReleaseProductCardComponent', productData);
    useEffect(() => {
        /*
        async function getData() {
            try {
                //await getPastProduct(customerNumberId, productData.product_id)
            } catch (err) {
                console.log(err);
            }
        }
        getData();
        */
    }, [])
    return (
        <div className="releaseProductCard">
            <div className="productCardTop">
                <div className="productCardImg">
                    <img
                        src={productData.product_image}
                        alt='productImg'
                    />
                </div>
                <div className="productCardContent cardFixing">
                    <div className="contentName">{productData.product_name}</div>
                    <div className="contentColor">{productData.store_pid}</div>
                    <div className="contentPrice">{productData.store}</div>
                </div>
                <div>
                    {
                        isShowPast ?
                            <div className="productCardLabel">
                                Past
                            </div>
                            : ''
                    }
                </div>
            </div>
            {
                isShowDetails ?
                    <div className="productCardBottom">
                        <div className="productCardBottomLeft">
                            <div className="productLabel">
                                Total Checkout
                            </div>
                            <div className="productContent">
                                {`${checkoutAmount} Items`}
                            </div>
                        </div>
                        <div className="productCardBottomRight">
                            <div className="productLabel">
                                Money Spent
                            </div>
                            <div className="productContent">
                                {`$${moneySpentAmount}`}
                            </div>
                        </div>
                    </div> : ''
            }
            <div className="productCardBtn" onClick={handleBtnClick}>
                {
                    isShowDetails ?
                        <Button
                            type="primary"
                            className="cardBtn"
                        >
                            Hide Detail
                        </Button> :
                        <Button
                            type="primary"
                            className="cardBtn"
                        >
                            View Detail
                        </Button>
                }
            </div>
        </div>
    )
}