import React from 'react';
import { Button, Checkbox } from 'antd';
import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import './UpdateCard.css';
import Close from '../../icons/v2/Close.svg';
import Success from '../../icons/v2/Success.svg';
import Failure from '../../icons/v2/Failure.svg';
import { getPlatformBackend } from '../../utils/platform';
import Stripe from '../../icons/v2/Stripe.svg';
import axios from 'axios';
import CardInput from './CardInput';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { LogoLoaderComponent } from '..';

export function UpdateCardComponent(
    props
) {

    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = React.useState(false);
    const [isShowResult, setisShowResult] = React.useState(false);
    const [isSuccuess, setisSuccuess] = React.useState(false);
    const [resultTitle, setResultTitle] = React.useState('');
    const [resultDescription, setResultDescription] = React.useState('Error');
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);
    const [cardName, setCardName] = React.useState('');

    const handleSubmitSub = async (event) => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (cardName === '') {
            setResultTitle("Oops, Something's Wrong");
            setResultDescription("Please enter your name on card");
            setisSuccuess(false);
            setisShowResult(true);
            return;
        }

        if (!acceptedTerms) {
            setResultTitle("Oops, Something's Wrong");
            setResultDescription("Please accept the terms and conditions");
            setisSuccuess(false);
            setisShowResult(true);
            return;
        }

        //console.log(elements.getElement(CardElement));

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: cardName,
            }
        });

        console.log(result);

        setLoading(true);
        setisShowResult(false);
        setisSuccuess(false);

        if (result.error) {
            console.log(result.error.message);
            setLoading(false);
            setResultTitle("Oops, Something's Wrong");
            setResultDescription(result.error.message);
            setisShowResult(true);
            setisSuccuess(false);
            
        } else {
            let res;    

            try {
                res = await axios.post(getPlatformBackend() + '/api/stripe/updatePaymentInfo', { "customerId": props.customerId, "payment_method": result.paymentMethod.id, "acceptedTerms": acceptedTerms });

                console.log("Ressssi", res);
            } catch (err) {
                console.log(err);
                res = err.response;
                // Display the error message to the user
                //setErrorMessage('An error occurred while creating the subscription');
                setLoading(false);
                setResultTitle("Oops, Something's Wrong");
                if (res) {
                    setResultDescription(res.data.err[0].msg);
                }
                setisShowResult(true);
                setisSuccuess(false);
                return;
            }

            // window.location.reload(false);
            setLoading(false);
            setResultTitle("Updating your payment info was successful");
            setResultDescription("All new payments will be issued to this credit card");
            setisShowResult(true);
            setisSuccuess(true);
            setTimeout(() => {
                props.updateCustomerCard(props.customerId);
            }, 50);
        }
    };

    const backToHome = () => {
        props.closeCardPopup();
    }

    const tryAgain = () => {
        setisShowResult(false);
    }

    const onChangeCheckbox = (e) => {
        setAcceptedTerms(e.target.checked);
    }

    const handleCardName = (e) => {
        setCardName(e.target.value);
    }

    return (
        <div className='popup'>
            {
                isShowResult ?
                    <div className='popup_inner'>
                        {
                            isSuccuess ? (
                                <div>
                                    <div className="resultIconContainer">
                                        <img src={Success} alt="successIcon" className="resultIcon" />
                                    </div>
                                    <div className="resultTitle">
                                        {resultTitle}
                                    </div>
                                    <div className="resultDesc">
                                        {resultDescription}
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            className="backToBtn"
                                            onClick={backToHome.bind(this)}
                                        >
                                            Back To Home
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="resultIconContainer">
                                        <img src={Failure} alt="failIcon" className="resultIcon" />
                                    </div>
                                    <div className="resultTitle">
                                        {resultTitle}
                                    </div>
                                    <div className="resultDesc">
                                        {resultDescription}
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            className="backToBtn"
                                            onClick={tryAgain.bind(this)}
                                        >
                                            Try Again
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div> :
                    loading ? (
                        <LogoLoaderComponent></LogoLoaderComponent>
                    ) :
                        <div className='popup_inner'>
                            <img src={Close} alt="closeIcon" className="closeIcon" onClick={props.closeCardPopup} />
                            <div className="updateAddressTitle">
                                Update Credit Card
                            </div>
                            <div className="updateAddressTitle updateCardTitle">
                                50€
                                <div className="updateCardSubTitle">/ Month</div>
                            </div>
                            <div className="updatePaymentRoot">
                                <div className="updatePaymentContent">
                                    <div className="updateAddressColumn stripCardInputSection">
                                        <div className="updateAddressLabel">
                                            Card Info<span className="updateAddressLabelStar">*</span>
                                        </div>
                                        <CardInput />
                                    </div>
                                    <div className="updateAddressColumn">
                                        <div className="updateAddressLabel">
                                            Name on Card<span className="updateAddressLabelStar">*</span>
                                        </div>
                                        <input placeholder="Name" onChange={handleCardName} value={cardName} />
                                    </div>
                                    {/* <Button type="primary" className="submitButton" onClick={handleSubmitSub}>Renew Now</Button> */}
                                    <div className="updateCardDesc">
                                        <Checkbox onChange={onChangeCheckbox}>I confirm that I have read and understand the <a href="/terms">Terms and Conditions</a> and the <a href="/cancellationpolicy">Cancellation Policy</a>, and I agree to be bound by them.</Checkbox>
                                    </div>
                                    <input type="submit" className="submitButton" value="Update" onClick={handleSubmitSub} />
                                    <div className="updateCardFooter">
                                        <img src={Stripe} alt="stripIcon" />
                                    </div>
                                </div>
                            </div>
                        </div>
            }
        </div>
    );
}