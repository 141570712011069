export function filterReleaseDate(fullList) {
    const dateObj = {};
    // const fullListData = { ...fullList };
    // Object.keys(fullListData).forEach(key => {
    //     if (dateObj[fullListData[key].releaseDate]) {
    //         dateObj[fullListData[key].releaseDate]++;
    //     } else {
    //         dateObj[fullListData[key].releaseDate] = 1;
    //     }
    // });

    const fullListData = [...fullList];
    fullListData.forEach(ele => {
        const date = new Date(ele.release_date);
        const formattedDate = `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
        if (dateObj[formattedDate]) {
            dateObj[formattedDate]++;
        } else {
            dateObj[formattedDate] = 1;
        }
    });

    return dateObj;
}

export function dateGenerator(data) {
    console.log('dateGenerator', data);
    let month = data.getMonth();
    const monthMapping = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const monthLiteMapping = [
        'Jan',
        'Feb',
        'March',
        'April',
        'May',
        'June',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    let date = data.getDate();
    let year = data.getFullYear();
    const dateData = {
        month: monthMapping[month],
        monthLite: monthLiteMapping[month],
        date,
        year,
    }
    console.log('dateGenerator', dateData);
    return dateData;
}

export function checkoutChartDataGenerator(data, type) {
    //hard to understand lmao
    let checkoutData = [];
    let spentData = [];
    let dateObj;
    let count = 0;
    console.log('checkoutChartDataGenerator', data, type);
    if (Object.keys(data.Details).length > 0 || data.Details.length > 0) {
        if (type === 'All') {
            //owned since
            let date = new Date()
            let ownedSince = new Date(data.ownedSince);

            let diffInMilliSeconds = Math.abs(date - ownedSince) / 1000;
            const month = Math.floor(diffInMilliSeconds / 2628000);
            diffInMilliSeconds -= month * 2628000;

            console.log("MONTHH", month);

            checkoutData = new Array(month + 1).fill(0);
            spentData = new Array(month + 1).fill(0);
            
            data.Details.forEach(ele => {
                let datee = new Date(ele.alll);

                let diffInMilliSeconds = Math.abs(datee - ownedSince) / 1000;
                const month = Math.floor(diffInMilliSeconds / 2628000);
                diffInMilliSeconds -= month * 2628000;
                
                //checkoutData[month] = ele.checkouts;
                
                if (checkoutData[month] !== 0) {
                    checkoutData[month] = checkoutData[month] + ele.checkouts;
                } else {
                    checkoutData[month] = ele.checkouts;
                }

                if (spentData[month] !== 0) {
                    spentData[month] = parseFloat(spentData[month]) + parseFloat(ele.moneySpent);
                } else {
                    spentData[month] = parseFloat(ele.moneySpent);
                }
            
            });
        } else if (type === 'This Year') {
            checkoutData = new Array(52).fill(0);
            spentData = new Array(52).fill(0);

            let dateNow = new Date();
            let dateBefore = new Date(dateNow.getTime() - (365 * 24 * 60 * 60 * 1000));

            data.Details.forEach(ele => {
                let date = new Date(ele.yearr);
                
                let diffInMilliSeconds = Math.abs(date - dateBefore) / 1000;
                const week = Math.floor(diffInMilliSeconds / 604800);
                diffInMilliSeconds -= week * 604800;
                
                console.log("WEEKs month", week);

                if (checkoutData[week] !== 0) {
                    checkoutData[week] = checkoutData[week] + ele.checkouts;
                } else {
                    checkoutData[week] = ele.checkouts;
                }
        
                if (spentData[week] !== 0) {
                    spentData[week] = parseFloat(spentData[week]) + parseFloat(ele.moneySpent);
                } else {
                    spentData[week] = parseFloat(ele.moneySpent);
                }

                //checkoutData[ele.yearr - 1] = ele.checkouts;
            });
        } else if (type === 'This Month') {
            checkoutData = new Array(31).fill(0);
            spentData = new Array(31).fill(0);
            let dateNow = new Date();
            //get date of date now - 31 days
            let dateBefore = new Date(dateNow.getTime() - (31 * 24 * 60 * 60 * 1000));


            data.Details.forEach(ele => {
                let date = new Date(ele.monthh);
            
                let diffInMilliSeconds = Math.abs(date - dateBefore) / 1000;

                const days = Math.floor(diffInMilliSeconds / 86400);
                diffInMilliSeconds -= days * 86400;
                console.log("DAYs month", days);

                if (checkoutData[days] !== 0) {
                    checkoutData[days] = checkoutData[days] + ele.checkouts;
                } else {
                    checkoutData[days] = ele.checkouts;
                }

                if (spentData[days] !== 0) {
                    spentData[days] = parseFloat(spentData[days]) + parseFloat(ele.moneySpent);
                } else {
                    spentData[days] = parseFloat(ele.moneySpent);
                }

                //checkoutData[ele.monthh - 1] = ele.checkouts;
            });
        } else if (type === 'This Week') {
            checkoutData = new Array(7).fill(0);
            spentData = new Array(7).fill(0);

            let dateNow = new Date();
            //get date of date now - 7 days
            let dateBefore = new Date(dateNow.getTime() - (7 * 24 * 60 * 60 * 1000));

            data.Details.forEach(ele => {
                //checkoutData[ele.weekk] = ele.checkouts;
                let date = new Date(ele.weekk);
                let diffInMilliSeconds = Math.abs(date - dateBefore) / 1000;

                const days = Math.floor(diffInMilliSeconds / 86400);
                diffInMilliSeconds -= days * 86400;
                console.log("DAYs", days);

                if (checkoutData[days] !== 0) {
                    checkoutData[days] = checkoutData[days] + ele.checkouts;
                } else {
                    checkoutData[days] = ele.checkouts;
                }

                if (spentData[days] !== 0) {
                    spentData[days] = parseFloat(spentData[days]) + parseFloat(ele.moneySpent);
                } else {
                    spentData[days] = parseFloat(ele.moneySpent);
                }
            
            });
        } else if (type === 'Today') {
            checkoutData = new Array(24).fill(0);
            spentData = new Array(24).fill(0);

            let dateNow = new Date();
            const dayAgo = new Date(dateNow.getTime());
            dayAgo.setDate(dateNow.getDate() - 1);

            data.Details.forEach(ele => {
                let date = new Date(ele.dayy);
    
                let diffInMilliSeconds = Math.abs(date - dayAgo) / 1000;
                const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
                diffInMilliSeconds -= hours * 3600;
                console.log("HOURs", hours);
                
                if (checkoutData[hours] !== 0) {
                    checkoutData[hours] = checkoutData[hours] + ele.checkouts;
                } else {
                    checkoutData[hours] = ele.checkouts;
                }

                if (spentData[hours] !== 0) {
                    spentData[hours] = parseFloat(spentData[hours]) + parseFloat(ele.moneySpent);
                } else {
                    spentData[hours] = parseFloat(ele.moneySpent);
                }
            });
        }
    } else {
        checkoutData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        spentData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    /*
    if (Object.keys(data).length > 0 || data.length > 0) {
        if (type === 'All') {
            checkoutData = new Array(36).fill(0);
            data.forEach((ele) => {
                count += ele.count;
                dateObj = new Date(ele.creation);
                console.log('checkoutChartDataGenerator', dateObj.getMonth(), checkoutData[dateObj.getMonth()]);
                checkoutData[dateObj.getMonth()] = checkoutData[dateObj.getMonth()] + ele.count;
            });
        } else if (type === 'This Year') {
            checkoutData = new Array(12).fill(0);
            data.forEach((ele) => {
                count += ele.count;
                dateObj = new Date(ele.creation);
                console.log("DATEOBJ", dateObj);
                console.log('checkoutChartDataGenerator', dateObj.getMonth(), checkoutData[dateObj.getMonth()]);
                checkoutData[dateObj.getMonth()] = checkoutData[dateObj.getMonth()] + ele.count;
                console.log("YEAR CHECKOOUT DATA", checkoutData);
            });
        } else if (type === 'This Month') {
            checkoutData = new Array(31).fill(0);
            data.forEach((ele) => {
                count += ele.count;
                dateObj = new Date(ele.creation);
                console.log('checkoutChartDataGenerator', dateObj.getDate() - 1, checkoutData[dateObj.getDate() - 1]);
                checkoutData[dateObj.getDate() - 1] = checkoutData[dateObj.getDate() - 1] + ele.count;
            });
        } else if (type === 'This Week') {
            checkoutData = new Array(7).fill(0);
            data.forEach((ele) => {
                count += ele.count;
                dateObj = new Date(ele.creation);
                console.log('checkoutChartDataGenerator', dateObj.getDay(), checkoutData[dateObj.getDay()]);
                checkoutData[dateObj.getDay()] = checkoutData[dateObj.getDay()] + ele.count;
            });
        } else if (type === 'Today') {
            checkoutData = new Array(24).fill(0);
            data.forEach((ele) => {
                count += ele.count;
                dateObj = new Date(ele.creation);
                console.log('checkoutChartDataGenerator', dateObj.getHours() - 1, checkoutData[dateObj.getHours() - 1]);
                checkoutData[dateObj.getHours() - 1] = checkoutData[dateObj.getHours() - 1] + ele.count;
            });
        } else {
            checkoutData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
    } else {
        checkoutData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    */
    console.log("CHECKOUT DATA", checkoutData);
    console.log("SPENT DATA", spentData);
    return {checkoutData, spentData};
}

export function spentChartDataGenerator(data, type) {
    /*
    let spentData = [];
    let dateObj;
    let count = 0;
    console.log('spentChartDataGenerator', data, type);
    if (Object.keys(data).length > 0 || data.length > 0) {
        if (type === 'All') {
            spentData = new Array(12).fill(0);
            data.forEach((ele) => {
                dateObj = new Date(ele.creation);
                spentData[dateObj.getMonth()] = spentData[dateObj.getMonth()] + Number(ele.price) * ele.count;
                count += Number(ele.price) * ele.count;
            });
        } else if (type === 'This Year') {
            spentData = new Array(12).fill(0);
            data.forEach((ele) => {
                dateObj = new Date(ele.creation);
                spentData[dateObj.getMonth()] = spentData[dateObj.getMonth()] + Number(ele.price) * ele.count;
                count += Number(ele.price) * ele.count;
            });
        } else if (type === 'This Month') {
            spentData = new Array(31).fill(0);
            data.forEach((ele) => {
                dateObj = new Date(ele.creation);
                spentData[dateObj.getDate() - 1] = spentData[dateObj.getDate() - 1] + Number(ele.price) * ele.count;
                count += Number(ele.price) * ele.count;
            });
        } else if (type === 'This Week') {
            spentData = new Array(7).fill(0);
            data.forEach((ele) => {
                dateObj = new Date(ele.creation);
                spentData[dateObj.getDay()] = spentData[dateObj.getDay()] + Number(ele.price) * ele.count;
                count += Number(ele.price) * ele.count;
            });
        } else if (type === 'Today') {
            spentData = new Array(24).fill(0);
            data.forEach((ele) => {
                dateObj = new Date(ele.creation);
                spentData[dateObj.getHours() - 1] = spentData[dateObj.getHours() - 1]  + Number(ele.price) * ele.count;
                count += Number(ele.price) * ele.count;
            });
        } else {
            spentData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
    } else {
        spentData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    
    console.log('spentChartDataGenerator spentData', spentData);

    return {spentData, count};
    */
    let count = 0;
    let spentData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    return { spentData, count };
}

export function filterUpcomingProducts(fullList, targetDate) {
    const result = [];
    console.log('filterUpcomingProducts fullList', fullList, targetDate);
    fullList.forEach(ele => {
        const date = new Date(ele.release_date);
        const formattedDate = `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
        console.log('filterUpcomingProducts formattedDate date', formattedDate, targetDate);
        if (formattedDate === targetDate) {
            result.push(ele);
        }
    });
    console.log('filterUpcomingProducts result', result);

    return result;
}