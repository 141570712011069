import React from 'react';
import './PaymentCard.css';
import * as CommonApplicationHelper from '../../helpers/CommonApplicationHelper';

export function PaymentCardComponent({
    paymentInfo
}) {
    console.log('paymentInfo', paymentInfo);
    const date = new Date(paymentInfo.created * 1000);
    const dateUpdated = CommonApplicationHelper.dateGenerator(date);
    let currencyUnit = '';
    if (paymentInfo.currency === 'eur') {
        currencyUnit = '€';
    } else if (paymentInfo.currency === 'usd') {
        currencyUnit = '$';
    } else if (paymentInfo.currency === 'pound') {
        currencyUnit = '£';
    }
    console.log('paymentInfo date', date);
    return (
        <div className="paymentCard">
            <div className="paymentCardInner">
                <div className="paymentCardItem">
                    <div className="infoContent">{`${dateUpdated.month} Subscription`}</div>
                    <div className="infoContent">{`${(paymentInfo.total/100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`}</div>
                </div>
                <div className="paymentCardItem">
                    <div className="infoLabel">{`${dateUpdated.date} ${dateUpdated.monthLite} ${dateUpdated.year}`}</div>
                    <div className="viewInvoiceCtaGroup">
                        <div className="viewInvoiceCta">
                            <a href={paymentInfo.hosted_invoice_url} target="_blank">View Invoice</a>
                        </div>
                        <div className="viewInvoiceCta">
                            <a href={paymentInfo.invoice_pdf} target="_blank">Download PDF</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}