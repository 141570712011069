
export function getPlatformBackend() {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://www.qbotaio.com';
}

export function getPlatformFrontend() {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://www.qbotaio.com';
}

export function getPlatformBackendA() {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://auth.qbotaio.com';
}