import React from 'react';
import './TermsPage.css';
import {
    GlobalFootersComponent,
    GlobalHeadersComponent,
} from '../../components';

export function TermsPage(props) {

    return (
        <div>
            <GlobalHeadersComponent />
            <div className="privacyPolicyContainer">
                <div className="textPrivacyContainer">
                    <h1>Terms & Conditions</h1>
                    <h2>1.0 Introduction</h2>
                        <p>The following General Terms and Conditions of Sale (hereinafter referred to as “TOS”) set out the terms and conditions between you, the customer, and “Q Technologies GmbH” (“us”, “we”), governing the use of our website and our downloadable digital recordings including the content therein (the “product(s)”). Your use of our website, and purchase, download and use of our products, constitutes your full acceptance of these terms and conditions. If you do not agree with these terms and conditions, you should not use our website or purchase, download or use any of our products.

                        The version of the TOS valid at the time of the conclusion of the contract shall apply.</p>

                        <h2>2.0 Scope of Application</h2>
                        <p>The TOS shall apply exclusively. Deviating or additional terms and conditions of the customers, as well as the customer's own terms and conditions shall not become part of this contract, even we not expressly object to them.</p>

                        <h2>3.0 License and Use</h2>
                        <p>Your purchase of one of our products constitutes our granting to you of a non-exclusive, non-sublicensable, non-transferable license to download and access that product for the purpose of your own personal use and reference. You agree that under no circumstances shall you use, or permit to be used, any product other than for the aforesaid purpose. For the avoidance of doubt, you shall not copy, re-sell, sublicense, rent out, share or otherwise distribute any of our products, whether modified or not, to any third party. You agree not to use any of our products in a way which might be detrimental to us or damage our reputation. Buying a “lifetime”-license only means you get access to the product while it is still being worked on for further updates without having to pay a certain monthly/yearly renewal fee. Having a lifetime license doesn’t condole our product is being worked on forever. By purchasing a Renewal license you will be granted a 1 month access to the software and you’ll be given the opportunity to purchase another 1 months for a renewal fee. If you miss the renewal and your license expires you cede your right of renewal. Your key will be deleted. By using our software we do not condone buying shoes or other products with the intention of making profits by selling products which are acquired through our software or services. The products acquired with our software and services are meant for personal use only.</p>

                        <h2>4.0 Intellectual Property</h2>
                        <p>The products, whether modified or not, and all intellectual property and copyright contained therein, are and shall at all times remain our sole and exclusive property. You agree that under no circumstances, whether the product has been modified or not, shall you have or attempt to claim ownership of any intellectual property rights or copyright in the product.</p>

                        <h2>5.0 Refunds and Chargebacks</h2>
                        <p>The renewal fee can be changed at any time.
                        You are not allowed to exercise a cancellation of the contract (refer to “Cancellation Terms” at the bottom of the website and on the checkbox below the Purchase Button).</p>

                        <h2>6.0 Selling of Keys</h2>
                        <p>Selling of purchased keys is prohibited. You are not allowed to sell, gift or rent the product / key to someone else.</p>

                        <h2>7.0 Warranties and Liability</h2>
                        <p>We make every effort to ensure that our products are accurate, authoritative and fit for the use of our customers. However, we take no responsibility whatsoever for the suitability of the product, and we provide no warranties as to the function or use of the product, whether express, implied or statutory, including without limitation any warranties of merchantability or fitness for particular purpose. You agree to indemnify us against all liabilities, claims, demands, expenses, actions, costs, damages, or loss arising out of your breach of these terms and conditions. Furthermore, we shall not be liable to you or any party for consequential, indirect, special or exemplary damages including but not limited to damages for loss of profits, business or anticipated benefits whether arising under tort, contract, negligence or otherwise whether or not foreseen, reasonably foreseeable or advised of the possibility of such damages.</p>

                        <h2>8.0 General</h2>
                        <p>These terms and conditions constitute the entire agreement and understanding between you and us for the supply of downloadable digital products, and shall supersede any prior agreements whether made in writing, orally, implied or otherwise. The failure by us to exercise or enforce any right(s) under these terms and conditions shall not be deemed to be a waiver of any such right(s) or operate so as to bar the exercise or enforcement thereof at any time(s) thereafter, as a waiver of another or constitute a continuing waiver. You agree that monetary damages may not be a sufficient remedy for the damage which may accrue to us by reason of your breach of these terms and conditions, therefore we shall be entitled to seek injunctive relief to enforce the obligations contained herein. The unenforceability of any single provision within these terms and conditions shall not affect any other provision hereof. These terms and conditions, your acceptance thereof, and our relationship with you shall be governed by and construed in accordance with German law and both us and you irrevocably submit to the exclusive jurisdiction of the German courts over any claim, dispute or matter arising under or in connection with these terms and conditions or our relationship with you.</p>

                        <h2>9.0 Final Provisions</h2>
                        <p>Severability: If a provision of this agreement is or becomes legally invalid or if there is any gap that needs to be filled, the validity of the remainder of the agreement shall not be affected thereby. Invalid provisions shall be replaced by common consent with such provisions which come as close as possible to the intended result of the invalid provision. In the event of gaps such provision shall come into force by common consent which comes as close as possible to the intended result of the agreement, should the matter have been considered in advance. Any changes of or amendments to this Agreement must be in writing to become effective.
                        Force Majeure: If performance by either party is prevented, restricted or delayed due to any cause arising from or attributable to acts, events, non-happenings, omissions, accidents or acts of God beyond the reasonable control of the party due to perform, the party so affected shall be excused from performance to the extent of such prevention, restriction or delay. No party shall have any liability to any other party for delay or non-delivery in the performance of its obligations under this Agreement, when attributable to acts of God, compliance in good faith with any applicable foreign or domestic governmental regulation or order whether or not it proves to be invalid, fires, war, riots, labour disputes such as strikes or lockouts, sabotage, unusually severe weather or any other cause beyond the reasonable control of such Party.</p>

                        <h2>10.0 Privacy Policy and Cookie Policy</h2>
                        <p>Please refer to our Privacy Policy. You agree that they constitute part of these terms. You must read our Privacy Policy and TOS before you use the Service.
                        Platform of the EU Commission regarding online dispute resolution: https://ec.europa.eu/consumers/odr</p>
                </div>
            </div>
        </div>
    )
}