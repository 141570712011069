import React, { useEffect } from 'react';
import './CardDetails.css';
import { Button } from 'antd';
import cardPlacerholder from '../../icons/cc/cardPlacerholder.svg';
import cardPlaceholderNew from '../../icons/cc/cardPlaceholderNew.svg';
import visa from '../../icons/cc/visa@1,5x.svg';
import master from '../../icons/cc/mastercard@1,5x.svg';
import amex from '../../icons/cc/amex@1,5x.svg';
import { UpdateAddressComponent, UpdateCardComponent } from '..';
import { postGetCustomerDetails } from '../../utils/api';

export function CardDetailsComponent({
    address,
    cardDetail,
    customerId,
    licenseType,
    handleReload
}) {
    const [showPopup, setShowPopup] = React.useState(false);
    const [showCardPopup, setShowCardPopup] = React.useState(false);
    const [customerDetailsData, setCustomerDetailsData] = React.useState({
        "address": {
            "city": "",
            "country": "",
            "line1": "",
            "line2": "",
            "postal_code": "",
            "state": ""
        },
        "email": "",
        "name": "",
        "brand": "",
        "exp_month": "",
        "exp_year": "",
        "last4": "****"
    });
    const [isLifetime, setIsLifetime] = React.useState(false);
    const [isRental, setIsRental] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const postCustomerDetailsData = async (customer_id) => {
        let res = await postGetCustomerDetails(customer_id);
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("postGetCustomerDetails", res);
            return res.data;
        } else {
            return {
                "address": {
                    "city": "",
                    "country": "",
                    "line1": "",
                    "line2": "",
                    "postal_code": "",
                    "state": ""
                },
                "email": "",
                "name": "",
                "brand": "",
                "exp_month": "",
                "exp_year": "",
                "last4": "****"
            };
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await postCustomerDetailsData(customerId);
            setCustomerDetailsData(result);
            if (licenseType.toLowerCase() === "lifetime") {
                setIsLifetime(true);
            }
            if (licenseType.toLowerCase() === "monthly" || licenseType.toLowerCase() === "weekly" || licenseType.toLowerCase() === "daily") {
                setIsRental(true);
            }
            setLoading(false);
        }

        fetchData().catch(error => {
            console.log("error", error);
        });
    }, []);

    const updateCustomerAddress = async (customerId) => {
        setLoading(true);
        console.log('updateCustomerAddress');
        let updateCustomerInfo = await postCustomerDetailsData(customerId);
        setCustomerDetailsData(updateCustomerInfo);
        setLoading(false);
    }

    const updateCustomerCard = async (customerId) => {
        console.log("IN HERRREE");
        setTimeout(() => {
            handleReload();
        }, 1000);
        /*
        setLoading(true);
        console.log('updateCustomerCard');
        const result = await postCustomerDetailsData(customerId);
        setCustomerDetailsData(result);
        setLoading(false);
        */
    }

    console.log("customerDetailsData", customerDetailsData);

    const togglePopup = () => {
        if (!showPopup) {
            setShowPopup(true);
        } else {
            setShowPopup(false);
        }
    }

    const toggleCardPopup = () => {
        if (!showCardPopup) {
            setShowCardPopup(true);
        } else {
            setShowCardPopup(false);
        }
    }

    return (
        <div className="cardInfoContainer">
            <h1 className="infoHeader">Billing info</h1>
            <div className="cardBox">
                <img src={cardPlaceholderNew} alt="Card" className="cardPlaceholder" />
                <div className="cardLast4">{`****${customerDetailsData.last4}`}</div>
                {
                    customerDetailsData.brand === 'visa' ? <img src={visa} alt="Visa" className="cardType" /> :
                    customerDetailsData.brand === 'mastercard' ? <img src={master} alt="Master" className="cardType" /> :
                    customerDetailsData.brand === 'amex' ? <img src={amex} alt="Amex" className="cardType" /> : ""
                }
            </div>
            <h2 className="cardLabel">Details</h2>
            <div className="cardContentHolder">
                <div className="cardContent">
                    <h2 className="cardContentLeft">Expiration</h2>
                    <h2 className="cardContentRight">{`${customerDetailsData.exp_month}/${customerDetailsData.exp_year}`}</h2>
                </div>
                <div className="cardContent">
                    <h2 className="cardContentLeft">Currency</h2>
                    <h2 className="cardContentRight">€</h2>
                </div>
                <div className="cardContent">
                    <h2 className="cardContentLeft">Card Type</h2>
                    <h2 className="cardContentRight">{customerDetailsData.brand}</h2>
                </div>
            </div>
            <h2 className="cardLabel">Billing Address</h2>
            <h2 className="cardAddress">
                {`${customerDetailsData.address.line1},${customerDetailsData.address.line2},${customerDetailsData.address.city},${customerDetailsData.address.state},${customerDetailsData.address.postal_code},${customerDetailsData.address.country}`}
            </h2>
            <div className="cardBtnContainer">
                <Button
                    type="primary"
                    className="cardBtn"
                    onClick={togglePopup.bind(this)}
                    disabled={isLifetime || isRental || loading}
                >
                    Update Billing Address
                </Button>
                <Button
                    type="primary"
                    className="cardBtn"
                    onClick={toggleCardPopup.bind(this)}
                    disabled={isLifetime || isRental || loading}
                >
                    Update Card
                </Button>
            </div>
            {showPopup ?
                <UpdateAddressComponent
                    closePopup={togglePopup.bind(this)}
                    addy={customerDetailsData}
                    customerId={customerId}
                    updateCustomerAddress={updateCustomerAddress}
                />
                : null
            }
            {showCardPopup ?
                <UpdateCardComponent
                    closeCardPopup={toggleCardPopup.bind(this)}
                    customerId={customerId}
                    updateCustomerCard={updateCustomerCard}
                />
                : null
            }
        </div>
    )
}