import React from 'react';
import { useEffect } from 'react';
import './CreateRelease.css';
import { useForm } from 'react-hook-form';
import Close from '../../icons/v2/Close.svg';


export function CreateReleaseComponent( {
    closePopup
} ) {

    const { register, handleSubmit, reset } = useForm();

    const onSubmit = async (data) => {
        console.log("on SUbmit");
    }

    //change form
    return (
        <div className="popupnew">
            <div className="popup_inner">
            <img src={Close} alt="closeIcon" className="closeIcon" onClick={closePopup}/>
                    <div className="updateAddressTitle">
                        Create Release
                    </div>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="updateAddressColumn">
                            <div className="updateAddressLabel">
                                Email<span className="updateAddressLabelStar">*</span>
                            </div>
                            <input {...register("email")} placeholder="E-Mail" />
                        </div>
                        <div className="updateAddressColumn">
                            <div className="updateAddressLabel">
                                Type<span className="updateAddressLabelStar">*</span>
                            </div>
                            <input {...register("Renewal")} placeholder="Renewal" />
                        </div>
                        <div className="updateAddressColumn">
                            <div className="updateAddressLabel">
                                Bla<span className="updateAddressLabelStar">*</span>
                            </div>
                            <input {...register("Bla")} placeholder="Bla" />
                        </div>
                        <input type="submit" className="submitButton" value="Create license"/>
                    </form>
            </div>
        </div>
    )
}