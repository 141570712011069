import React, { useEffect } from 'react';
import './OrderStatistics.css';
import ShoppingCart from '../../icons/v2/ShoppingCart.svg';
import MoneySpent from '../../icons/v2/MoneySpent.svg';
import {
    DropdownComponent
} from '../Dropdown';
import { TinyArea } from '@ant-design/charts';
import * as CommonApplicationHelper from '../../helpers/CommonApplicationHelper';
import { getOrderStatistics } from '../../utils/api';

export function OrderStatisticsComponent({
    orderData,
    user,
    licenseId,
    history
}) {
    console.log('orderComponentS orderData', orderData);
    /*
    const data = [
        264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513, 546, 983, 340, 539,
        243, 226, 192,
    ];
    */
   //const [data, setData] = React.useState({All: [], Year: [], Month: [], Week: [], Day: []});
    const [data, setData] = React.useState({All: {Overview:{}, Details:[], ownedSince: ""}, Year: {Overview:{}, Details:[]}, Month: {Overview:{}, Details:[]}, Week: {Overview:{}, Details:[]}, Day: {Overview:{}, Details:[]}});
    console.log("OMG LICENSE DAISDAD", licenseId);

    const getCheckoutsUserData = async (user_id, license_id) => {
        let res = await getOrderStatistics(user_id, license_id);
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getCheckoutsProductData", res);
            return res.data;
        } else {
            //return {All: [], Year: [], Month: [], Week: [], Day: []};
            return {All: {Overview:{}, Details:[], ownedSince: ""}, Year: {Overview:{}, Details:[]}, Month: {Overview:{}, Details:[]}, Week: {Overview:{}, Details:[]}, Day: {Overview:{}, Details:[]}};
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await getCheckoutsUserData(user.id, licenseId);
            setData(result);
        }

        fetchData().catch(error => {
            console.log("error", error);
        });
    }, []);

    

    const dropdownOption = [
        "All",
        "This Year",
        "This Month",
        "This Week",
        "Today"
    ];
    const dropdownMapping = {
        "All": "All",
        "This Year": "Year",
        "This Month": "Month",
        "This Week": "Week",
        "Today": "Day",
    }
    const dropdownDetailMapping = {
        "All": "all",
        "This Year": "year",
        "This Month": "month",
        "This Week": "week",
        "Today": "day",
    }

    const [selectedRange, setselectedRange] = React.useState(dropdownOption[0]);

    const handleSetselectedRange = (data) => {
        console.log('handleSetselectedRange', data);
        setselectedRange(data);
    }

    let checkoutChartData = CommonApplicationHelper.checkoutChartDataGenerator(data[dropdownMapping[selectedRange]], selectedRange);
    const checkoutData = checkoutChartData.checkoutData;
    //const checkoutCount = checkoutChartData.count;
    let checkoutCount = data[dropdownMapping[selectedRange]].Overview.checkouts;
    console.log('checkoutData', checkoutData, checkoutCount);

    const configCheckout = {
        height: 150,
        width: 230,
        autoFit: false,
        data: checkoutData,
        smooth: true,
        areaStyle: { 
            fillOpacity: 0.1,
            lineWidth: 3,
            fill: '#0d9fff',
        },
        line: {
            color: '#0d9fff',
            size: 2
        }
        // animation: {
        //     appear: {
        //         animation: 'path-in',
        //         duration: 5000
        //     }
        // },
    };

    const configCheckoutMobile = {
        height: 150,
        width: 180,
        autoFit: false,
        data: checkoutData,
        smooth: true,
        areaStyle: { 
            fillOpacity: 0.1,
            lineWidth: 3,
            fill: '#0d9fff',
        },
        line: {
            color: '#0d9fff',
            size: 2
        }
        // animation: {
        //     appear: {
        //         animation: 'path-in',
        //         duration: 5000
        //     }
        // },
    };

    //let spentChartData = CommonApplicationHelper.spentChartDataGenerator(data[dropdownMapping[selectedRange]], selectedRange);
    const spentData = checkoutChartData.spentData;
    //const spentCount = spentChartData.count;
    let spentCount = data[dropdownMapping[selectedRange]].Overview.moneySpent;
    console.log('spentData', spentData, spentCount);

    const configSpent = {
        height: 150,
        width: 230,
        autoFit: false,
        data: spentData,
        smooth: true,
        areaStyle: { 
            fillOpacity: 0.1,
            lineWidth: 3,
            fill: '#ff9e2d',
        },
        line: {
            color: '#ff9e2d',
            size: 2
        }
        // animation: {
        //     appear: {
        //         animation: 'path-in',
        //         duration: 5000
        //     }
        // },
    };

    const configSpentMobile = {
        height: 150,
        width: 180,
        autoFit: false,
        data: spentData,
        smooth: true,
        areaStyle: { 
            fillOpacity: 0.1,
            lineWidth: 3,
            fill: '#ff9e2d',
        },
        line: {
            color: '#ff9e2d',
            size: 2
        }
        // animation: {
        //     appear: {
        //         animation: 'path-in',
        //         duration: 5000
        //     }
        // },
    };

    console.log("SPENT COUNT", spentCount);
    if (spentCount === undefined) {
        spentCount = 0;
    }

    console.log("Checkout Count", checkoutCount);
    if (checkoutCount === undefined) {
        checkoutCount = 0;
    }

    return(
        <div className="infoContainer orderStatisticContainer">
            <div className="orderStatisticTop">
                <div className="infoHeader orderStatisticHeader">Order Statistics</div>
                <div className="orderStatisticSelection">
                    <DropdownComponent 
                        options={dropdownOption}
                        selectedRange={selectedRange}
                        handleSetselectedRange={handleSetselectedRange}
                    />
                </div>
            </div>
            <div className="orderStatisticBody">
                <div className="orderStatisticItem">
                    <div className="statisticItemLeft">
                        <div className="statisticItemLeftTop">
                            <img src={ShoppingCart} alt="checkoutIcon" className="statisticItemIcom"/>
                            <div className="statisticItemLabel">Total Checkouts</div>
                        </div>
                        <div className="statisticItemMid">
                            <div className="statisticItemMidData">{checkoutCount}</div>
                            <div className="statisticItemMidUnit">Items</div>
                        </div>
                        {/* <div className="statisticItemBottom colorGreen">+30% this year</div> */}
                    </div>
                    <div className="statisticChart desktopView">
                        <TinyArea {...configCheckout} />
                    </div>
                    <div className="statisticChart mobileView">
                        <TinyArea {...configCheckoutMobile} />
                    </div>
                </div>
                <div className="orderStatisticItem">
                    <div className="statisticItemLeft">
                        <div className="statisticItemLeftTop">
                            <img src={MoneySpent} alt="moneyIcon" className="statisticItemIcom"/>
                            <div className="statisticItemLabel">Money Spent</div>
                        </div>
                        <div className="statisticItemMid">
                            <div className="statisticItemMidData">{`$${Math.floor(spentCount)}`}</div>
                            {/*<div className="statisticItemMidUnit">{`,${((spentCount - Math.floor(spentCount)).toFixed(2)) * 100}`}</div>*/}
                        </div>
                        {/* <div className="statisticItemBottom colorRed">-10% this year</div> */}
                    </div>
                    <div className="statisticChart desktopView">
                        <TinyArea {...configSpent} />
                    </div>
                    <div className="statisticChart mobileView">
                        <TinyArea {...configSpentMobile} />
                    </div>
                </div>
            </div>
            {/* {checkoutsYear.map((checkout) => (
                <li key={checkout.id}>{checkout.price}</li>
            ))} */}
        </div>
    )
}
