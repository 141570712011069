import React from 'react';
import { Button, Collapse } from 'antd';
import './LandingPage.css';
import axios from 'axios';
import { useEffect } from 'react';
import {
    GlobalFootersComponent,
    GlobalHeadersComponent,
} from '../../components';
import atc from '../../assets/hero-image-atc.png';
import checkout from '../../assets/hero-image-checkout.png';
import iddle from '../../assets/hero-image-iddle.png';
import anlyatics from '../../assets/analytics.png';
import task from '../../assets/task.png';
import Purchase from '../../icons/v2/Purchase.svg';
import Logo from '../../icons/logo.svg';
import WOW from 'wowjs';
import $ from 'jquery';
import { getPlatformBackend, getPlatformFrontend } from '../../utils/platform';


const { Panel } = Collapse;

export function LandingPage(props) {
    useEffect(() => {
        const wow = new WOW.WOW({
            live: false,
        });
        wow.init();

        window.addEventListener('scroll', handleScroll, true);
    });

    const handleScroll = () => {
        const scroll = document.getElementById('root').scrollTop;
        if (scroll > 0) {
            console.log('scroll', scroll);  
            if (scroll >= 150) {
                $(".statusIdle").addClass("hideHero");
            } else {
                $(".statusIdle").removeClass("hideHero");
            }
    
            if (scroll >= 300) {
                $(".statusAtc").addClass("hideHero");
            } else {
                $(".statusAtc").removeClass("hideHero");
            }
        }
    }
    const loginDashboard = () => window.location.href = getPlatformBackend() + "/api/discord/check";
    const goToMarketplace = () => window.location.href = getPlatformFrontend() + "/marketplace";
    const hanldeBtnClick = (str) => {
        window.location.href = str;
    }
    // const [externalFile, setexternalFile] = React.useState({});
    // const getExternalFile = () => {
    //     console.log('test1028 calling');
    //     return axios.get('/indexNew.html').then(
    //      (respone) => {
    //          console.log('test1028', respone);
    //          setexternalFile(respone.data)
    //     }).catch((err) => {
    //         console.log('test1028 error', err);
    //     }) }

    // useEffect(() => {
    //     async function getData() {
    //         try {
    //             getExternalFile()
    //         } catch (err) {

    //         }
    //     }
    //     getData();
    // }, [])
    return (
        <div>
            <GlobalHeadersComponent />
            {/* <div style={{textAlign: "right"}}>
                <Button type="primary" style={{margin: "0.7rem"}}>Home</Button>
                <Button type="primary" style={{margin: "0.7rem"}} onClick={goToMarketplace}>Marketplace</Button>
                <Button type="primary" style={{margin: "0.7rem"}} onClick={loginDashboard}>Dashboard</Button>
            </div> */}
            <div className="landingContainer">
                <div className="landingInnerContainer">
                    <div className="heroHeader">
                        <div className="heroText">
                            <div className="heroTitle wow fadeInUp" data-wow-delay="0s">QBot <span className="textBlue">2.0</span></div>
                            <div className="heroDesc wow fadeInUp" data-wow-delay="0.2s">Known for consistency for more than three years. Enabling our users to purchase sought after shoes effortlessly.</div>
                            {/* <div className="action-header wow fadeInUp" data-wow-delay="0.3s">
                                <a href="#product-release" className="btn-custom btn-large btn-primary w-icon wow fadeInUp" data-wow-delay="0.4s">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="36" height="36" rx="8" fill="white" fill-opacity="0.16"/>
                                        <path d="M14.4985 15.9991C14.4985 17.9329 16.0662 19.5006 18 19.5006V19.5006C19.9338 19.5006 21.5015 17.9329 21.5015 15.9991" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.4985 12.9979V12.4977C14.4985 10.5639 16.0662 8.99622 18 8.99622V8.99622C19.9338 8.99622 21.5015 10.5639 21.5015 12.4977V12.9979" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4617 12.9979C11.4052 12.9979 10.5308 13.8195 10.4649 14.8739L10.0057 22.2211C9.92815 23.4614 10.3668 24.6786 11.2177 25.5843C12.0686 26.49 13.256 27.0038 14.4987 27.0038H21.5013C22.744 27.0038 23.9315 26.49 24.7824 25.5843C25.6333 24.6786 26.0719 23.4614 25.9944 22.2211L25.5352 14.8739C25.4693 13.8195 24.5949 12.9979 23.5383 12.9979H12.4617Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>							
                                    Purchase Now
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.96967 19.5303C7.7034 19.2641 7.6792 18.8474 7.89705 18.5538L7.96967 18.4697L14.439 12L7.96967 5.53033C7.7034 5.26406 7.6792 4.8474 7.89705 4.55379L7.96967 4.46967C8.23594 4.2034 8.6526 4.1792 8.94621 4.39705L9.03033 4.46967L16.0303 11.4697C16.2966 11.7359 16.3208 12.1526 16.1029 12.4462L16.0303 12.5303L9.03033 19.5303C8.73744 19.8232 8.26256 19.8232 7.96967 19.5303Z" fill="white"/>
                                    </svg>							
                                </a>
                            </div> */}
                            <div className="headerButtonItemSelected dashboardPurchaseBtn wow fadeInUp" data-wow-delay="0.3s">
                                <Button
                                    type="primary"
                                    style={{ margin: "0.7rem" }}
                                    onClick={() => hanldeBtnClick(getPlatformFrontend() + '/marketplace')}
                                    icon={<img src={Purchase} alt="Manage License" className="headerIcon purchaseIcon" />}
                                >
                                    Purchase Now
                                </Button>
                            </div>
                        </div>
                        <div className="heroImage wow fadeInUp" data-wow-delay="0.4s">
                            <img src={atc} alt="" className="statusAtc"/>
                            <img src={checkout} alt="" className="statusCheckout"/>
                            <img src={iddle} alt="" className="statusIdle showHero"/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="landingInnerContainer">
                    <div className="producPreviewContainer">
                        <div className="producPreviewInnerContainer">
                            <div className="titleWrapper">
                                <p className="productLabel wow fadeInUp" data-wow-delay="0.2s">PRODUCT PREVIEW</p>
                                <h2 className="productTitle wow fadeInUp" data-wow-delay="0.4s">The New Face Of QBot</h2>
                                <p className="productDesc wow mb0 fadeInUp" data-wow-delay="0.6s">A complety new user experience with everything rewritten and new sites.</p>
                            </div>
                            <div className="videoReview wow fadeInUp" data-wow-delay="0.8s">
                                <a id="playVideo" className="play-button" href="#">
                                    <span></span>
                                </a>
                                <div className="cover-video">
                                    <iframe width="100%" height="650" src="https://www.youtube.com/embed/5gL6LI9yiIM?disable_polymer=true" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>

                            <div className="titleWrapper textCenter">
                                <p className="productLabel wow fadeInUp" data-wow-delay="0s">PRODUCT PREVIEW</p>
                                <h2 className="productTitle wow fadeInUp" data-wow-delay="0.2s">GUI Insights</h2>
                            </div>

                            <div className="productAnalytics wow fadeInUp" data-wow-delay="0.4s">
                                <div className="productContentLeft">
                                    <p className="productLabel">ANALYTICS DASHBOARD</p>
                                    <h3 className="productInnerTitle">Real-time Analytic Overview</h3>
                                    <p className="productDesc mb0 body-1">QBot 2.0 introduces an integrated platform that allows real-time monitoring of purchased history. Products that have been successfully checked out are organised into distinct groups, enhancing the ease of tracking and order management. Here you will also find useful features such as how much has been spent that drop.</p>
                                </div>
                                <div className="productImg">
                                    <img src={anlyatics} alt="dashboard-analytics.pn"/>
                                </div>
                            </div>
                            <div className="productTask task wow fadeInUp" data-wow-delay="0.6s">
                                <div className="productContentLeft displayFlex">
                                    <div className="productLabelLeft">
                                        <p className="productLabel">TASKS</p>
                                        <h3 className="productInnerTitle">Manage Tasks Efficiently</h3>
                                    </div>
                                    <p className="productLabelRight productDesc mb0 body-1">QBot 2.0 now incorporates task grouping, enabling simultaneous execution across all supported sites, making it ideal for shock drops and restocks. In addition, multiple mass editing options are now available, enabling efficient and speedy modifications to your setup without the need for task restart. The process of changing servers has been further streamlined with the introduction of export features, allowing for quick and easy importation of your entire task setup in seconds!</p>
                                </div>
                                <div className="productTaskImg">
                                    <img src={task} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faqContainer">
                        <div className="faqInnerContainer wow fadeInUp" data-wow-delay="0.2s">
                            <div className="faqTitleContainer text-center">
                                <p className="fatTitle fadeInUp" data-wow-delay="0.2s">FAQ</p>
                                <h2 className="faqDesc mb-16 wow fadeInUp" data-wow-delay="0.4s">How can we help you?</h2>
                            </div>
                            <div className="faqAccordion wow fadeInUp" data-wow-delay="0.4s" id="AccordionFaq">
                                {/* <div className="accordionItem wow fadeInUp" data-wow-delay="0.4s">
                                    <h4 className="accordionHeader" id="flush-headingOne">
                                        <button className="accordionButton collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            Lorem ipsum dolor sit amet consectetur adipiscing elit ?
                                        </button>
                                    </h4>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#AccordionFaq">
                                        <div className="accordion-body">
                                            <p className="body-2 text-n-2 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam et molestie dis. Risus, sit turpis id sit semper tincidunt ultrices. Elementum sed congue tincidunt consectetur blandit. Nulla pellentesque id mattis purus nibh quis integer. Pretium in leo at lorem purus risus elementum.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordionItem wow fadeInUp" data-wow-delay="0.6s">
                                    <h4 className="accordionHeader" id="flush-headingTwo">
                                        <button className="accordionButton collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            How can we join?
                                        </button>
                                    </h4>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#AccordionFaq">
                                        <div className="accordion-body">
                                            <p className="body-2 text-n-2 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam et molestie dis. Risus, sit turpis id sit semper tincidunt ultrices. Elementum sed congue tincidunt consectetur blandit. Nulla pellentesque id mattis purus nibh quis integer. Pretium in leo at lorem purus risus elementum.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordionItem wow fadeInUp" data-wow-delay="0.8s">
                                    <h4 className="accordionHeader" id="flush-headingThree">
                                        <button className="accordionButton collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Can we can cancel at any time?
                                        </button>
                                    </h4>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#AccordionFaq">
                                        <div className="accordion-body">
                                            <p className="body-2 text-n-2 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam et molestie dis. Risus, sit turpis id sit semper tincidunt ultrices. Elementum sed congue tincidunt consectetur blandit. Nulla pellentesque id mattis purus nibh quis integer. Pretium in leo at lorem purus risus elementum.</p>
                                        </div>
                                    </div>
                                </div> */}
                                <Collapse defaultActiveKey={['1']}>
                                    <Panel header="How can we join?" key="1">
                                    <p>{"No planned restocks for further news and updates follow us on Twitter."}</p>
                                    </Panel>
                                    <Panel header="Are you open for groupbuys?" key="2">
                                    <p>{"Yes we are open for groupbuys. If you are interested contact us via Twitter or E-Mail"}</p>
                                    </Panel>
                                    <Panel header="What countries are supported?" key="3">
                                    <p>{"We are a mainly EU focused bot however we do support some sites which can benefit Asia and Oceanic members"}</p>
                                    </Panel>
                                </Collapse>
                            </div>
                            <p className="faqBottomDesc high-emphasis text-center">Didn’t find what you looking for? <br /> Send us an email at <a href="mailto:help@qbotaio.com">help@qbotaio.com</a> and we’ll get in touch with you</p>
                        </div>
                    </div>
                    <div className="purchaseContainer">
                        <div className="purchaseInnerContainer">
                            <div className="logoPurchase wow fadeInUp" data-wow-delay="0s">
                                <img src={Logo} alt=""/>					
                            </div>
                            <div className="purchaseTitleContainer">
                                <h2 className="purchaseTitle wow fadeInUp" data-wow-delay="0.2s">Excited For <span className="textBlue">QBot</span>?</h2>
                                <p className="purchaseDesc body-1 wow fadeInUp" data-wow-delay="0.4s">Turn on notifications on Twitter to keep updated with the latest news.</p>	
                            </div>
                            <div className="headerButtonItemSelected dashboardPurchaseBtn">
                                <Button
                                    type="primary"
                                    style={{ margin: "0.7rem" }}
                                    onClick={() => hanldeBtnClick(getPlatformFrontend() + '/marketplace')}
                                    icon={<img src={Purchase} alt="Manage License" className="headerIcon purchaseIcon" />}
                                >
                                    Purchase Now
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div dangerouslySetInnerHTML={{ __html: externalFile }} /> */}
            <GlobalFootersComponent />
        </div>
    )
}