import axios from 'axios';
import { getPlatformBackend, getPlatformBackendA } from './platform';

axios.defaults.withCredentials = true;

export async function getUserDetailsAdmin() {
    //no catch to return to homepage on error
    
    return await axios.get(getPlatformBackend() + "/api/discord/info/admin", {
        withCredentials: true,
    });
}

export async function getUserDetailsUser() {
    //no catch to return to homepage on error
    
    return await axios.get(getPlatformBackend() + "/api/discord/info/user", {
        withCredentials: true,
    });
    
}

export async function getUserDetailsTransition() {
    //no catch to return to homepage on error

    return await axios.get(getPlatformBackend() + "/api/discord/info/transition", {
        withCredentials: true,
    });
}

export async function getReleases() {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + "/api/releases", {
            withCredentials: true
        });
    } catch (error) {
        response = error.response
    }
    return response;
}

export async function getUserInfo(user_id) {
    return await axios.get(getPlatformBackend() + `/api/users/id/${user_id}`, {
        withCredentials: true
    });
}

export async function getAllLicensesUser() {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + "/api/licenses/users", {
            withCredentials: true
        });
    } catch (error) {
        response = error.response
    }
    return response;
}

export async function getAllLicensesHistory() {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + "/api/licensesHistory", {
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function postCreateCustomer(dataObj) {
    let response;
    try {
        response = await axios.post(getPlatformBackend() + "/api/stripe/createCustomer", {
            data: {
                "email": dataObj.email,
                "address": {
                    "city": dataObj.city,
                    "country": dataObj.country,
                    "line1": dataObj.line1,
                    "line2": dataObj.line2,
                    "postal_code": dataObj.postal_code,
                    "state": dataObj.state
                },
                "name": dataObj.name
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function postCancelSubscription(customerId) {
    let response;
    try {
        response = await axios.post(getPlatformBackend() + "/api/stripe/cancelSubscription", {
            data: {
                "customer_id": customerId
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function getStripeCustomer(user_id) {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + `/api/stripeCustomer/id/${user_id}`, {
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function postGetCustomerDetails(customerId) {
    let response;
    try {
        response = await axios.post(getPlatformBackend() + "/api/stripe/customerDetails", {
            data: {
                "customer_id": customerId
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function postUpdateCustomerInfo(dataObj, customerId) {
    let response;
    try {
        response = await axios.post(getPlatformBackend() + "/api/stripe/updateCustomerInfo", {
            data: {
                "customer_id": customerId,
                "email": dataObj.email,
                "address": {
                    "city": dataObj.city,
                    "country": dataObj.country,
                    "line1": dataObj.line1,
                    "line2": dataObj.line2,
                    "postal_code": dataObj.postal_code,
                    "state": dataObj.state
                },
                "name": dataObj.name
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function postGetUpcomingSubscription(customerId) {
    let response;
    try {
        response = await axios.post(getPlatformBackend() + "/api/stripe/subscriptionInfo", {
            data: {
                "customer_id": customerId
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function postGetSubscriptionPayment(customerId) {
    let response;
    try {
        response = await axios.post(getPlatformBackend() + "/api/stripe/invoices", {
            data: {
                "customer_id": customerId
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

//OLD
export async function getOrderStatistics(user_id, license_id) {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + `/api/checkouts/user/${user_id}/license/${license_id}`, {
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

//NOT NEEDED
export async function getOrderStatisticsDetails(timeRange, user_id, license_id,productId) {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + `/api/checkouts/${timeRange}/user/${user_id}/license/${license_id}/product/${productId}`, {
            withCredentials: true
        });
    } catch (error) {
        response = error.response;
    }
    return response;
}

export async function postCloseClients(license) {
    let response;
    try {
        response = await axios.post(getPlatformBackendA() + "/stopallclients", {
            data: {
                "license": license
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response
    }
    return response;
}

export async function postCloseClient(license, authLicense) {
    let response;
    try {
        response = await axios.post(getPlatformBackendA() + "/stopclient", {
            data: {
                "license": license,
                "auth_license": authLicense
            },
            withCredentials: true
        });
    } catch (error) {
        response = error.response
    }
    return response;
}

export async function getCheckoutsProduct() {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + "/api/checkouts/allProducts", {
            withCredentials: true
        });
    } catch (error) {
        response = error.response
    }
    return response;
}

export async function getAllCheckoutsAdmin() {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + "/api/checkouts/admin/allCheckouts", {
            withCredentials: true
        });
    } catch (error) {
        response = error.response
    }
    return response;
}

export async function getAllCheckoutsUser(user_id, license_id) {
    let response;
    try {
        response = await axios.get(getPlatformBackend() + `/api/checkouts/allCheckouts/user/${user_id}/license/${license_id}`, {
            withCredentials: true
        });
    } catch (error) {
        response = error.response
    }
    return response;
}