import React from 'react';
import { Button, Row } from 'antd';
import './ProductCard.css';

export function ProductCardComponent({
    productName,
    productStore,
    productPrice,
    productImage,
    retailerSku,
    totalCheckouts,
    successCheckouts,
    failedCheckouts
}) {

    return (


        <div className="productCard">
            <div className="left">
                <div className="properties">{productName}</div>
                <img src={productImage} alt="chartIcon" className="pic" />
                <div className="properties">{retailerSku}</div>
            </div>
            <div className="right">
                <div className="productStore properties">{productStore}</div>
                <div className="allCheckouts">
                    <div className="checkouts">
                        <div className="checkoutNumber">{totalCheckouts}</div>
                        <div className="checkoutLabel">Total</div>
                    </div>
                    <div className="checkouts">
                        <div className="checkoutNumber successCheckout">{successCheckouts}</div>
                        <div className="checkoutLabel">Success</div>
                    </div>
                    <div className="checkouts">
                        <div className="checkoutNumber failedCheckout">{failedCheckouts}</div>
                        <div className="checkoutLabel">Failed</div>
                    </div>
                </div>
                
            </div>

        </div>


    )
}