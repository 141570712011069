import React, { useEffect } from 'react';
import './Info.css';
import { Button } from 'antd';
import { postGetUpcomingSubscription, postCancelSubscription, getDownloadLink } from '../../utils/api';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Info from '../../icons/v2/Info.svg';
import { LogoLoaderComponent } from '..';
import Close from '../../icons/v2/Close.svg';
import Success from '../../icons/v2/Success.svg';
import Failure from '../../icons/v2/Failure.svg';
import { MD5 } from "crypto-js";

export function InfoComponent({
    info,
    upcomingCharge,
    customerId,
    handleReload
}) {
    const [isShowFullKey, setisShowFullKey] = React.useState(true);
    const [upcomingChargeData, setUpcomingChargeData] = React.useState({});
    const [isShowDate, setIsShowDate] = React.useState(true);
    const [date, setDate] = React.useState("-");
    const [subscriptionStatusBoolean, setSubscriptionStatusBoolean] = React.useState(true);
    const [resultTitle, setResultTitle] = React.useState('');
    const [resultDescription, setResultDescription] = React.useState('Error');
    const [isShowResult, setisShowResult] = React.useState(false);
    const [isSuccuess, setisSuccuess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isShowInfo, setIsShowInfo] = React.useState(false);
    const [isShowIcon, setIsShowIcon] = React.useState(false);
    const [popUpText, setPopUpText] = React.useState('');
    const [isShowCancel, setIsShowCancel] = React.useState(true);
    const [showButtonLoading, setShowButtonLoading] = React.useState(true);
    const [isShowPopupCancel, setIsShowPopupCancel] = React.useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const postUpcomingSubscriptionData = async (customer_id) => {
        console.log("CustomerID", customer_id);
        let res = await postGetUpcomingSubscription(customer_id);
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("postUpcomingSubscriptionData", res);
            return res.data;
        } else {
            return {};
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await postUpcomingSubscriptionData(customerId);
            console.log("result upcoming charge", result);
            setUpcomingChargeData(result);
            setShowButtonLoading(false);
        }

        //setIsLifetime(info.license_type);

        fetchData().catch(error => {
            console.log("error", error);
        });
    }, [])

    useEffect(() => {
        console.log("upcomingChargeData", upcomingChargeData);

        if (Object.keys(upcomingChargeData).length !== 0) {
            console.log("upcomingChargeeeeee", upcomingChargeData.upcomingCharge);
            if (upcomingChargeData.cancelAtPeriodEnd === true) {
                setIsShowCancel(false);
            }

            if (upcomingChargeData.upcomingCharge !== null) {
                setDate(new Date(upcomingChargeData.upcomingCharge).toLocaleString());
            }
            if (upcomingChargeData.status !== "active") {
                setSubscriptionStatusBoolean(false);
                if (upcomingChargeData.status === "Lifetime") {
                    setDate("-");
                    setSubscriptionStatusBoolean(true);
                }
                if (upcomingChargeData.paymentIntentStatus === "requires_action") {
                    console.log("innnnnnnnnnnn past_due");
                    setPopUpText("Please verify your card with 3D Secure by clicking the button on the left. Failure to complete this verification within 7 days of your first payment attempt results in the termination of your license.");
                    setIsShowDate(false);
                    setIsShowIcon(true);
                } else if (upcomingChargeData.paymentIntentStatus === "requires_payment_method") {
                    console.log("innnnnnnnnnnn past_due elseif");
                    setPopUpText("We were unable to process your card payment. We suggest updating your card information. Keep in mind, if the payment fails four times, your license will be revoked.");
                    setIsShowIcon(true);
                } else if (upcomingChargeData.paymentIntentStatus !== null) {
                    console.log("innnnnnnnnnnn past_due else", upcomingChargeData.paymentIntentStatus);
                    setPopUpText("We were unable to process your card payment. Keep in mind, if the payment fails four times, your license will be revoked.");
                    setIsShowIcon(true);
                }
            }
        }
    }, [upcomingChargeData])

    const handleShowKey = () => {
        console.log('handleShowKey');
        if (isShowFullKey) {
            setisShowFullKey(false);
        } else {
            setisShowFullKey(true);
        }
    }

    const postCancelSubscriptionReq = async (customer_id) => {
        let res = await postCancelSubscription(customer_id);
        if (res !== null && res !== undefined && res.status === 200) {
            return res.data;
        } else {
            return { "success": false };
        }
    }

    const handleCancelSubscription = async () => {
        let result = await postCancelSubscriptionReq(customerId);
        console.log("result lololol", result);
        if (result.success === true) {
            //success
            console.log("success cancellled");
            setTimeout(() => {
                handleReload();
            }, 1000);
        } else {
            //error
            console.log("error cancellled");
        }
    }

    const handleDownloadInstaller = async () => {
        let md5Key = MD5(info.license_key).toString();
        let downloadUrl = "https://www.qbotaio.com/api/versions/latest/download?key=" + md5Key;
        window.open(downloadUrl, "_blank");
    }

    const acceptThreeD = async () => {

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);


        setisShowResult(false);
        setisSuccuess(false);

        if (upcomingChargeData.paymentIntentStatus !== null && upcomingChargeData.paymentIntentStatus === "requires_action") {
            stripe.confirmCardPayment(upcomingChargeData.clientSecret).then(function (result) {
                console.log("RESULTT", result);
                if (result.error) {
                    console.log('There was an issue!');
                    console.log(result.error);
                    //show issue

                    setLoading(false);
                    setResultTitle("Oops, Something's Wrong");
                    setResultDescription(result.error.message);
                    setisShowResult(true);
                    setisSuccuess(false);
                    setTimeout(() => {
                        handleReload();
                    }, 1000);
                } else {
                    console.log('You got the money!');
                    //show success and form of rerender

                    setLoading(false);
                    setResultTitle("Your Payment was Successful! Redirecting...");
                    setResultDescription("Thanks for renewing your subscription!");
                    setisShowResult(true);
                    setisSuccuess(true);
                    setTimeout(() => {
                        handleReload();
                    }, 1000);
                }
            });
        } else {
            setLoading(false);
            setResultTitle("Oops, Something's Wrong");
            setResultDescription("Couldn't accept 3D secure");
            setisShowResult(true);
            setisSuccuess(false);
            setTimeout(() => {
                handleReload();
            }, 1000);
        }

    }

    const backToHome = () => {
        //props.closeCardPopup();
        setisShowResult(false);
    }

    const tryAgain = () => {
        setisShowResult(false);
    }

    const handleClosePopupCancel = () => {
        setIsShowPopupCancel(false);
    }


    return (
        <>
            <div className="licenseDetailContainer">
                <h1 className="infoHeader">Licence Detail</h1>
                <div className="infoBody">
                    <div className="infoBodyColumn">
                        <div className="infoBodyItem">
                            <h2 className="infoLabel">Email</h2>
                            <h2 className="infoContent">{info.email}</h2>
                        </div>
                        <div className="infoBodyItem">
                            <h2 className="infoLabel">Subscription Status</h2>
                            <h2
                                className={
                                    subscriptionStatusBoolean ?
                                        "infoContent statusActive" :
                                        "infoContent statusDisable"
                                }
                            >
                                {upcomingChargeData.status}
                            </h2>
                        </div>
                    </div>
                    <div className="infoBodyColumn">
                        <div className="infoBodyItem">
                            <h2 className="infoLabel">License key ({info.license_type})</h2>
                            <div className="infoContent">
                                {
                                    isShowFullKey ? "****-****-****-****-****-****" : info.license_key
                                }
                                <div className="keyCta" onClick={() => handleShowKey()}>
                                    {
                                        isShowFullKey ? "Show" : "Hide"
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="infoBodyItem">
                            <h2 className="infoLabel">Next Charge</h2>
                            <h2 className="infoContent">
                                {
                                    isShowDate ? date :
                                        <Button
                                            type="primary"
                                            style={{ borderRadius: 10 }}
                                            onClick={acceptThreeD}
                                        >
                                            Accept 3D Secure
                                        </Button>
                                }
                                {isShowIcon &&
                                    <img
                                        src={Info}
                                        alt="notificationIcon"
                                        className="infoThreeD unselectable cursorPointer"
                                        onMouseEnter={() => setIsShowInfo(true)}
                                        onMouseLeave={() => setIsShowInfo(false)}
                                    />
                                }
                                {isShowInfo && <div className="infoPopup">{popUpText}</div>}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="infoBtnContainer">
                    <Button
                        type="primary"
                        style={{ margin: "0.7rem" }}
                        className="deactiveBtn"
                        onClick={() => handleDownloadInstaller()}
                        disabled={showButtonLoading}
                    >
                        Download Installer
                    </Button>
                    <Button
                        type="primary"
                        style={{ margin: "0.7rem" }}
                        className="cancelBtn"
                        //onClick={() => handleCancelSubscription()}
                        onClick={() => setIsShowPopupCancel(true)}
                        disabled={showButtonLoading || !isShowCancel || info.license_type === "Lifetime" || info.license_type === "Monthly" || info.license_type === "Weekly" || info.license_type === "Daily"}
                    >
                        Cancel Subscription
                    </Button>
                </div>
            </div>
            {
                isShowResult ?
                    <div className='popup' style={{ zIndex: 9999 }}>
                        <div className='popup_inner'>
                            {
                                isSuccuess ? (
                                    <div>
                                        <div className="resultIconContainer">
                                            <img src={Success} alt="successIcon" className="resultIcon" />
                                        </div>
                                        <div className="resultTitle">
                                            {resultTitle}
                                        </div>
                                        <div className="resultDesc">
                                            {resultDescription}
                                        </div>
                                        <div>
                                            <Button
                                                type="primary"
                                                className="backToBtn"
                                                onClick={backToHome.bind(this)}
                                            >
                                                Back To Home
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="resultIconContainer">
                                            <img src={Failure} alt="failIcon" className="resultIcon" />
                                        </div>
                                        <div className="resultTitle">
                                            {resultTitle}
                                        </div>
                                        <div className="resultDesc">
                                            {resultDescription}
                                        </div>
                                        <div>
                                            <Button
                                                type="primary"
                                                className="backToBtn"
                                                onClick={tryAgain.bind(this)}
                                            >
                                                Try Again
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div> : isShowPopupCancel ? (
                        <div className='popup' style={{ zIndex: 9999 }}>
                            <div className='popup_inner'>
                                <img src={Close} alt="closeIcon" className="closeIcon" onClick={handleClosePopupCancel} />
                                <div className="resultIconContainer">
                                    <img src={Failure} alt="warningIcon" className="resultIcon" />
                                </div>
                                <div className="resultTitle">
                                    Cancel Subscription
                                </div>
                                <div className="resultDesc">
                                    Are you sure you would like to cancel your subscription? Please note you will loose your key after the end of the billing cycle. You cannot resubscribe once you cancel your subscription.
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        className="backToBtn"
                                        onClick={handleCancelSubscription.bind(this)}
                                    >   Yes
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        loading ? (
                            <LogoLoaderComponent></LogoLoaderComponent>
                        )
                            :
                            null
            }
        </>
    )
}