import React, { Fragment, useEffect } from 'react';
import { Button } from 'antd';
import './AdminLicenses.css';
import { CreateLicenseComponent, LicenseListComponent } from '../../components';
import { ValidationPopupComponent } from '../ValidationPopup';
import { postCloseClients, getAllLicensesUser, getUserInfo, postCloseClient } from '../../utils/api';
import Success from '../../icons/v2/Success.svg';
import Failure from '../../icons/v2/Failure.svg';

export function AdminLicensesComponent({
    adminId
}) {

    const [createLicensePopup, setCreateLicensePopup] = React.useState(false);
    const [validationPopup, setValidationPopup] = React.useState(false);
    const [returnPopup, setReturnPopup] = React.useState(false);

    const [isShowResult, setIsShowResult] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);

    const [data, setData] = React.useState([]);

    const getLicenseData = async () => {
        let res = await getAllLicensesUser();
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getLicenseData", res);
            return res.data;
        } else {
            return [];
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const result = await getLicenseData();
            setData(result);
        }


        fetchData().catch(error => {
            console.log("error", error);
        });
    }, []);

    const toggleCreateLicensePopup = () => {
        if (!createLicensePopup) {
            setCreateLicensePopup(true);
        } else {
            setCreateLicensePopup(false);
        }
    }

    const toggleValidationPopup = () => {
        if (!validationPopup) {
            setValidationPopup(true);
        } else {
            setValidationPopup(false);
        }
    }

    const toggleReturnPopup = () => {
        setIsShowResult(false);
    }

    const closeClients = async (license) => {
        let authLicense = await getUserInfo(adminId);
        let res = await postCloseClient(license, authLicense.data.license_key);

        if (res !== null && res !== undefined) {
            if (res.status === 200) {
                //success
                setIsShowResult(true);
                setIsSuccess(true);
            } else {
                //error
                setIsShowResult(true);
                setIsSuccess(false);
            }
        } else {
            //error
            setIsShowResult(true);
            setIsSuccess(false);
        }
    }

    const closeAllClients = async (value) => {
        console.log("closeAllClients: ", value);
        setValidationPopup(false);

        if (value !== "confirm") {
            //error
            setIsShowResult(true);
            setIsSuccess(false);
        } else {
            //send request to close all clients

            let license = await getUserInfo(adminId);
            let res = await postCloseClients(license.data.license_key);
            console.log("Response", res);

            if (res !== null && res !== undefined) {
                if (res.status === 200) {
                    //success
                    setIsShowResult(true);
                    setIsSuccess(true);
                } else {
                    //error
                    setIsShowResult(true);
                    setIsSuccess(false);
                }
            } else {
                //error
                setIsShowResult(true);
                setIsSuccess(false);
            }

        }
    }

    let renewals = [];
    let lifetimes = [];
    let openClients = [];
    data.forEach(license => {
        if (license.license_type === "Renewal") {
            renewals.push(license);
        } else if (license.license_type === "Lifetime") {
            lifetimes.push(license);
        }

        if (license.ws_id !== null) {
            openClients.push(license)
        }
    });


    return (
        <div>
            <div>
                {isShowResult ?
                    <div className="popupsuccess">
                        <div className="popup_inner">
                            {
                                isSuccess ?
                                    <div>
                                        <div className="resultIconContainer">
                                            <img src={Success} alt="successIcon" className="resultIcon" />
                                        </div>
                                        <div className="resultTitle">
                                            Closed all clients
                                        </div>
                                        <div>
                                            <Button
                                                type="primary"
                                                className="backToBtn"
                                                onClick={toggleReturnPopup.bind(this)}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </div> :
                                    <div>
                                        <div className="resultIconContainer">
                                            <img src={Failure} alt="failIcon" className="resultIcon" />
                                        </div>
                                        <div className="resultDesc">
                                            Something went wrong
                                        </div>
                                        <div>
                                            <Button
                                                type="primary"
                                                className="backToBtn"
                                                onClick={toggleReturnPopup.bind(this)}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    : null}
            </div>
            <div className="adminStatHeader alignCenter">
                <div className="adminStatHeaderLabel">License overview</div>
                <div className="adminButton absoluteRight cardBtnContainerNew">
                    <Button type="primary" className="cardBtnNew" onClick={toggleValidationPopup.bind(this)}>Close all clients</Button>
                    <Button type="primary" className="cardBtnNew" onClick={toggleCreateLicensePopup.bind(this)}>Create license</Button>
                </div>
                {createLicensePopup ?
                    <CreateLicenseComponent closePopup={toggleCreateLicensePopup.bind(this)} />
                    : null}
                {validationPopup ?
                    <ValidationPopupComponent closePopup={toggleValidationPopup.bind(this)} validationQuestion={<Fragment>Type in <i>confirm</i> to confirm</Fragment>} submitFunction={closeAllClients} title={"Sure to close all clients?"} />
                    : null}
            </div>
            <div>
                <div className="adminLicenseBodyTop">
                    <div className="adminStatLicenseTopInner">
                        <div className="adminLicenseItem">
                            <div className="adminLicenseItemTop">
                                <div className="adminLicenseItemAmount">
                                    {data.length}
                                </div>
                                <div className="adminLicenseItemLabel">
                                    Total Licenses
                                </div>
                            </div>

                        </div>
                        <div className="adminLicenseItem">
                            <div className="adminLicenseItemTop">
                                <div className="adminLicenseItemAmount renewalColor">
                                    {renewals.length}
                                </div>
                                <div className="adminLicenseItemLabel">
                                    Renewal
                                </div>
                            </div>
                        </div>
                        <div className="adminLicenseItem">
                            <div className="adminLicenseItemTop">
                                <div className="adminLicenseItemAmount lifetimeColor">
                                    {lifetimes.length}
                                </div>
                                <div className="adminLicenseItemLabel">
                                    Lifetime
                                </div>
                            </div>
                        </div>
                        <div className="adminLicenseItem">
                            <div className="adminLicenseItemTop">
                                <div className="adminLicenseItemAmount openClientsColor">
                                    {openClients.length}
                                </div>
                                <div className="adminLicenseItemLabel">
                                    Open clients
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="adminLicenseBodyBottom">
                    <LicenseListComponent licenseList={data} closeClients={closeClients}></LicenseListComponent>
                </div>
            </div>
        </div>
    )
}