import React from 'react';
import { useEffect } from 'react';
import './AdminOrderHistory.css';
import { Input, Spin, Space, Button, Table } from 'antd';
import Search from '../../icons/search.svg';
import DetailGrey from '../../icons/v2/DetailGrey.svg';
import DetailBlue from '../../icons/v2/DetailBlue.svg';
import ArrowBack from '../../icons/v2/ArrowBack.svg';
import axios from 'axios';
import {
    getOrderStatisticsDetails
} from '../../utils/api';
import {
    DropdownComponent
} from '../Dropdown';
import { SearchOutlined } from '@ant-design/icons';

export function AdminOrderHistoryComponent({
    checkoutData
}) {
    let searchInput = React.createRef();
    let orderData = {
        //All: [], Day: [], Month: [], Week: [], Year: []
        All: checkoutData["AllSuccess"],
        Year: checkoutData["YearSuccess"],
        Month: checkoutData["MonthSuccess"],
        Week: checkoutData["WeekSuccess"],
        Day: checkoutData["DaySuccess"],
    }
    console.log('orderComponentH orderData', orderData);
    
    const dropdownOption = [
        "All",
        "This Year",
        "This Month",
        "This Week",
        "Today"
    ];
    const dropdownMapping = {
        "All": "All",
        "This Year": "Year",
        "This Month": "Month",
        "This Week": "Week",
        "Today": "Day",
    }
    const dropdownDetailMapping = {
        "All": "all",
        "This Year": "year",
        "This Month": "month",
        "This Week": "week",
        "Today": "day",
    }

    const [selectedRange, setselectedRange] = React.useState(dropdownOption[0]);
    const [isShowDetail, setisShowDetail] = React.useState(false);
    const [selectedProduct, setselectedProduct] = React.useState({});
    const [orderDataDetails, setorderDataDetails] = React.useState([]);
    const [isLoading, setisLoading] = React.useState(false);
    const [searchText, setsearchText] = React.useState('');
    const [searchedColumn, setsearchedColumn] = React.useState('');

    const handleSetselectedRange = (data) => {
        console.log('handleSetselectedRange', data);
        setselectedRange(data);
    }

    const handleOrderClick = (id, product, date, totalCheckout, moneySpent, productImage, price, sku) => {
        console.log('handleOrderClick', id);
        setselectedProduct({
            product,
            date,
            totalCheckout,
            moneySpent,
            productImage,
            price, sku
        })
        setisShowDetail(true);
        setisLoading(true);
    }

    const handleBackClick = () => {
        setisShowDetail(false);
        setselectedProduct({});
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setsearchText(selectedKeys[0]);
                            setsearchedColumn(dataIndex);
                            //   this.setState({
                            //     searchText: selectedKeys[0],
                            //     searchedColumn: dataIndex,
                            //   });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#0D9FFF' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text => (
            text
        ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        // this.setState({
        //   searchText: selectedKeys[0],
        //   searchedColumn: dataIndex,
        // });
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
        // this.setState({ searchText: '' });
    };

    const columns = [
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            sorter: {
                compare: (a, b) => a.product.length - b.product.length,
                multiple: 5,
            },
            render: (text, record) => {
                console.log('tableColumn product', text, record);
                return (
                    <div className="tableColumnProduct">
                        <img src={record.productImage} alt="chartIcon" className="tableProductIcon" />
                        <div className="tableProductTitle">{record.product}</div>
                    </div>
                );
            },
        },
        {
            title: 'SIZE',
            dataIndex: 'size',
            sorter: {
                compare: (a, b) => a.size - b.size,
                multiple: 4,
            },
        },
        {
            title: 'DATE',
            dataIndex: 'date',
            sorter: {
                compare: (a, b) => a.date - b.date,
                multiple: 3,
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            sorter: {
                compare: (a, b) => a.status - b.status,
                multiple: 2,
            },
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            sorter: {
                compare: (a, b) => a.price - b.price,
                multiple: 1,
            },
        },
        // {
        //     title: '',
        //     dataIndex: '',
        //     key: 'x',
        //     render: (text, record) => {
        //         console.log('tableColumn x', text, record);
        //         return (
        //             <img
        //                 src={DetailGrey}
        //                 alt="chartIcon"
        //                 className="orderItemIcon"
        //                 onClick={
        //                     () => handleOrderClick(
        //                         record.productId,
        //                         record.product,
        //                         record.releaseDate,
        //                         record.totalCheckout,
        //                         record.moneySpent,
        //                         record.productImage,
        //                         record.price,
        //                         record.sku)
        //                 }
        //             />
        //             // <div>{record.key}</div>
        //         );
        //     },
        // },
    ];

    const data = [];
    // for (let i = 0; i < 26; i ++) {
    //     data.push({
    //         key: i,
    //         product: `Nike Kobe Vi Protro - Men Shoes ${Math.floor(Math.random() * 100)}`,
    //         releaseDate: '10/04/2019',
    //         totalCheckout: Math.floor(Math.random() * 100),
    //         moneySpent: Math.floor(Math.random() * 10000)
    //     });
    // }
    console.log(orderData[dropdownMapping[selectedRange]]);
    if (orderData[dropdownMapping[selectedRange]].length > 0) {
        orderData[dropdownMapping[selectedRange]].forEach((ele, index) => {
            const date = new Date(ele.creation).toLocaleString();
            data.push({
                key: index,
                product: ele.product_name,
                productId: ele.product_id,
                productImage: ele.product_image,
                //date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                date : date,
                totalCheckout: ele.count,
                moneySpent: (Number(ele.count) * Number(ele.price)).toFixed(2),
                price: ele.price,
                size: ele.size,
                sku: ele.retailer_sku,
            });
        })
    }
    console.log('orderHistory 911 data', data, selectedRange);

    const columnsDetails = [
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            sorter: {
                compare: (a, b) => a.product.length - b.product.length,
                multiple: 4,
            },
        },
        {
            title: 'SIZE',
            dataIndex: 'size',
            sorter: {
                compare: (a, b) => a.size - b.size,
                multiple: 3,
            },
        },
        {
            title: 'DATE',
            dataIndex: 'releaseDate',
            sorter: {
                compare: (a, b) => a.releaseDate - b.releaseDate,
                multiple: 2,
            },
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            sorter: {
                compare: (a, b) => a.price - b.price,
                multiple: 1,
            },
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => {
                console.log('tableColumn x', text, record);
                return (
                    <img
                        src={DetailGrey}
                        alt="chartIcon"
                        className="orderItemIcon"
                    />
                    // <div>{record.key}</div>
                );
            },
        },
    ];
    const dataDetails = [];
    // for (let i = 0; i < 26; i ++) {
    //     dataDetails.push({
    //         key: i,
    //         product: `Nike Kobe Vi Protro - Men Shoes ${Math.floor(Math.random() * 100)}`,
    //         size: 'EU 10',
    //         date: '10/04/2019',
    //         price: Math.floor(Math.random() * 100)
    //     });
    // }
    if (orderDataDetails.length > 0) {
        orderDataDetails.forEach((ele, index) => {
            const date = new Date(ele.creation);
            dataDetails.push({
                key: index,
                product: ele.product_name,
                size: ele.size,
                productImage: ele.product_image,
                releaseDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                price: ele.price,
                sku: ele.retailer_sku,
            });
        })
    }

    console.log('orderHistoryData', dataDetails);

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    return (
        <div className="infoContainer">
            {
                isShowDetail ?
                    <div className="orderStatisticDetails">
                        <div className="detailsBack" onClick={handleBackClick}>
                            <img src={ArrowBack} alt="chartIcon" className="orderItemIcon" />
                            Back
                        </div>
                        <div className="orderDetailsTop paymentHeader">
                            <div className="orderDetailsLeft">
                                <div className="orderDetailsImg">
                                    <img
                                        src={selectedProduct.productImage}
                                        alt='productImg'
                                    />
                                </div>
                                <div className="productCardContent orderDetailsContent">
                                    <div className="contentName">{selectedProduct.product}</div>
                                    <div className="contentColor">{selectedProduct.sku}</div>
                                    <div className="contentPrice">{selectedProduct.price}</div>
                                    <div className="orderDetailsDate">{`Release Date: ${selectedProduct.date}`}</div>
                                </div>
                            </div>
                            <div className="productCardBottom orderDetailsRight">
                                <div className="productCardBottomLeft orderDetailCard">
                                    <div className="productLabel">
                                        Total Checkout
                                    </div>
                                    <div className="productContent">
                                        {`${selectedProduct.totalCheckout} Items`}
                                    </div>
                                </div>
                                <div className="productCardBottomRight orderDetailCard">
                                    <div className="productLabel">
                                        Money Spent
                                    </div>
                                    <div className="productContent">
                                        {`$${selectedProduct.moneySpent}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="orderHistoryTable">
                            {
                                isLoading ?
                                    <div className="orderHistorySpinner">
                                        <Spin size="large" />
                                    </div> :
                                    <Table columns={columnsDetails} dataSource={dataDetails} onChange={onChange} />
                            }
                        </div>
                    </div> :
                    <div>
                        <div className="orderStatisticTop paymentHeader">
                            <div orderStatisticHeader className="infoHeader orderStatisticHeader">Order History</div>
                            <div className="orderStatisticSelection">
                                {/* <div className="orderSearchHolder">
                                <Input
                                    className="orderSearch"
                                    placeholder="Search shoe, colour, brand"
                                    prefix={<img
                                        src={Search}
                                        alt="chartIcon"
                                        className=""
                                    />} 
                                />
                                
                            </div> */}
                                <DropdownComponent
                                    options={dropdownOption}
                                    selectedRange={selectedRange}
                                    handleSetselectedRange={handleSetselectedRange}
                                />
                            </div>
                        </div>
                        <div className="orderHistoryTable">
                            <Table columns={columns} dataSource={data} onChange={onChange} />
                        </div>
                    </div>
            }
            {/* {checkouts.map((checkout) => (
                <li key={checkout.id}>{checkout.product_name}, {checkout.price}, {checkout.size}, {checkout.store}, {checkout.order_number}</li>
            ))} */}
        </div>
    )
}