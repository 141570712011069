import React from 'react';
import { useEffect } from 'react';
import './LicenseHistoryList.css';
import { Input, Spin, Space, Button, Table } from 'antd';
import Search from '../../icons/search.svg';
import DetailGrey from '../../icons/v2/DetailGrey.svg';
import DetailBlue from '../../icons/v2/DetailBlue.svg';
import ArrowBack from '../../icons/v2/ArrowBack.svg';
import { SearchOutlined } from '@ant-design/icons';

export function LicenseHistoryListComponent({
    licenseHistoryList
}) {

    const columns = [
        {
            title: 'LICENSE',
            dataIndex: 'license_key',
        },
        {
            title: 'USERID',
            dataIndex: 'user_id',
        },
        {
            title: 'STRIPECUSTOMERID',
            dataIndex: 'stripe_customer_id',
        },
        {
            title: 'OWNEDFROM',
            dataIndex: 'owned_from',
        },
        {
            title: 'OWNEDTILL',
            dataIndex: 'owned_till',
        },
    ];

    const data = [];
    licenseHistoryList.forEach((ele, index) => {

        let ownedFrom;
        if (ele.owned_from !== null) {
            ownedFrom = new Date(ele.owned_from).toLocaleString();
        }

        let ownedTill;
        if (ele.owned_till !== null) {
            ownedTill = new Date(ele.owned_till).toLocaleString();
        }


        data.push({
            key: index,
            license_key: ele.license_key,
            user_id: ele.user_id,
            stripe_customer_id: ele.stripe_customer_id,
            owned_from: ownedFrom,
            owned_till: ownedTill,
        });
    });
    /*
    for (let i = 0; i < 26; i++) {
        data.push({
            key: i,
            license: `1111-1111-1111-1111`,
            type: 'Renewal',
            expiry: "10/04/2019",
            email: "blabal@gmail.com",
            discord: "333372675860987910",
            running: "true",
            lastlogout: "10/04/2019 132131",
        });
    }
    */

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    return (
        <div className="infoContainer">
            <div className="orderStatisticTop paymentHeader">
                <div className="infoHeader orderStatisticHeader">
                    License History
                </div>
                <div className="orderSearchHolder">
                    <Input
                        className="orderSearch"
                        placeholder="Search license, discordId"
                        prefix={<img
                            src={Search}
                            alt="chartIcon"
                            className=""
                        />}
                    />
                </div>
            </div>
            <div className="orderHistoryTable">
                <Table columns={columns} dataSource={data} onChange={onChange} />
            </div>
        </div>
    )
}