import React from 'react';
import './Headers.css';
import {
    Button,
    Divider,
    Badge,
    Avatar
} from 'antd';
import {
    InfoCircleFilled
} from '@ant-design/icons';
import Logo from '../../icons/logo.svg';
import Chart from '../../icons/chart.svg';
import ChartGrey from '../../icons/chartGrey.svg';
import Setting from '../../icons/setting.svg';
import Notification from '../../icons/notification.svg';
import Info from '../../icons/v2/Info.svg';
import Manage from '../../icons/v2/Manage.svg';
import MobileCross from '../../icons/v2/MobileCross.svg';
import MobileMenu from '../../icons/v2/MobileMenu.svg';
import { UpdateAddressComponent, UpdateCardComponent } from '..';
import { getPlatformFrontend, getPlatformBackend } from '../../utils/platform';

export function HeadersComponent({
    info,
    selectedTitle,
    handleSelectTitle,
    isShowMobileMenu,
    setIsShowMobileMenu,
    setIsShowCalendar
}) {

    const selectHeaders = (str) => {
        console.log('selectHeaders', str);
        handleSelectTitle(str);
        setIsShowMobileMenu(false);
    }

    const handleAlertMsg = () => {
        console.log('handleAlertMsg');
    }

    const handleInfoMsg = () => {
        console.log('handleInfoMsg');
    }

    const handleMobileMenu = () => {
        console.log('handleMobileMenu');
        if (isShowMobileMenu) {
            setIsShowMobileMenu(false);
        } else {
            setIsShowMobileMenu(true);
            setIsShowCalendar(false);
        }
    }

    const handleLogOut = async () => {
        console.log('handleLogOut');

        const response = await fetch(getPlatformBackend() + '/api/discord/logout', {
            method: 'DELETE',
        });

        if (response.ok) {
            document.cookie = "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.href = '/';
        } else {
            console.log('Failed to log out');
        }
    }

    const hanldeBtnClick = (str) => {
        window.location.href = str;
    }

    const [isShowNotification, setIsShowNotification] = React.useState(false);
    const [isShowBadge, setIsShowBadge] = React.useState(true);
    const [isShowInfo, setIsShowInfo] = React.useState(false);
    const [isShowLogoutDropdown, setIsShowLogoutDropdown] = React.useState(false);

    const handleLogoutDropdown = () => {
        console.log('handleLogoutDropdown');
        if (isShowLogoutDropdown) {
            setIsShowLogoutDropdown(false);
        } else {
            setIsShowLogoutDropdown(true);
        }
    }

    return (
        <div>
            <div className="headerDesktop headerContainer">
                <div className="logoIcon unselectable" onClick={() => hanldeBtnClick(getPlatformFrontend())}>
                    <img src={Logo} alt="logoIcon" />
                </div>
                <Divider type="vertical" className="headerIconDivider" />
                <div className={selectedTitle === "manageLicense" ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                    <Button
                        type="primary"
                        style={{ margin: "0.7rem", display: "flex", alignItems: "center" }}
                        onClick={() => selectHeaders('manageLicense')}
                        icon={<img src={Manage} alt="Manage License" className="headerIcon" />}
                    >
                        Manage License
                    </Button>
                </div>
                <div className={selectedTitle === "statistics" ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                    <Button
                        type="primary"
                        style={{ margin: "0.7rem", display: "flex", alignItems: "center" }}
                        onClick={() => selectHeaders('statistics')}
                        icon={<img src={Chart} alt="Statistics" className="headerIcon" />}
                    >
                        Statistics
                    </Button>
                </div>
                <div className="headerRight">
                    <Avatar
                        src={'https://cdn.discordapp.com/avatars/' + info.discord_id + '/' + info.avatar + '.png'}
                        className="userAvatar unselectable cursorPointer"
                        alt="User"
                        onClick={() => handleLogoutDropdown()}
                    />
                    {
                        isShowLogoutDropdown ?
                            <div className="logoutDropdown shadowBox">
                                <div className="desktopDiscordTag">{info.discord_tag}</div>
                                <div className="desktopLogout" onClick={() => handleLogOut()}>Log Out</div>
                            </div> : ''
                    }
                </div>
            </div>
            <div className="headerMobile headerContainer">
                <div className="logoIcon unselectable">
                    <img src={Logo} alt="logoIcon" />
                </div>
                <Divider type="vertical" className="headerIconDivider" />
                <div className="headerRight">
                    <div className="notificationIconHolder" onClick={() => handleMobileMenu()}>
                        <img
                            src={isShowMobileMenu ? MobileCross : MobileMenu}
                            alt="notificationIcon"
                            className="notificationIcon unselectable cursorPointer marginNone"
                        />
                    </div>
                </div>
            </div>
            {
                isShowMobileMenu ?
                    <div className="headerMobileMenu">
                        <div className={selectedTitle === "manageLicense" ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                            <Button
                                type="primary"
                                style={{ margin: "0.7rem" }}
                                onClick={() => selectHeaders('manageLicense')}
                                icon={<img src={Manage} alt="Manage License" className="headerIcon" />}
                            >
                                Manage License
                            </Button>
                        </div>
                        <div className={selectedTitle === "statistics" ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                            <Button
                                type="primary"
                                style={{ margin: "0.7rem" }}
                                onClick={() => selectHeaders('statistics')}
                                icon={<img src={Chart} alt="Statistics" className="headerIcon" />}
                            >
                                Statistics
                            </Button>
                        </div>
                        <Divider />
                        <div className="headerMobileInfo">
                            <div className="headerMobileAvatar">
                                <Avatar
                                    src={'https://cdn.discordapp.com/avatars/' + info.discord_id + '/' + info.avatar + '.png'}
                                    className="userAvatar unselectable"
                                    alt="User"
                                />
                            </div>
                            <div className="">
                                <div className="headerMobileTag">{info.discord_tag}</div>
                                <div className="headerMobileLogout" onClick={() => handleLogOut()}>Log Out</div>
                            </div>
                        </div>
                    </div> : ''
            }
        </div>
    )
}