import React, { useEffect } from 'react';
import './Payment.css';
import {
    PaymentCardComponent
} from '../PaymentCard';
import { Button } from 'antd';
import { postGetSubscriptionPayment } from '../../utils/api';

export function PaymentComponent({
    subPayment,
    customerId,
}) {
    console.log("SUBPAYMENT", subPayment);
    const [data, setData] = React.useState([]);

    const postSubscriptionPaymentData = async (customer_id) => {
        let res = await postGetSubscriptionPayment(customer_id);
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("postSubscriptionPaymentData", res);
            return res.data;
        } else {
            return [];
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await postSubscriptionPaymentData(customerId);
            setData(result.data);
        }

        fetchData().catch(error => {
            console.log("error", error);
        });
    }, [])

    const test = [
        {
            date: "15 Aug 2021",
            item: "Auguest",
            price: "$999.99"
        },
        {
            date: "15 July 2021",
            item: "July",
            price: "$999.99"
        },
        {
            date: "15 June 2021",
            item: "June",
            price: "$999.99"
        },
    ]
    return (
        <div className="paymentInfoContainer">
            <h1 className="infoHeader paymentHeader">Payment</h1>
            <div className="paymentBody">
                {
                    data && data.map((ele, index) => {
                        return (
                            <PaymentCardComponent 
                                paymentInfo={ele}
                                key={index}
                            />
                        );
                    })
                }
            </div>
        </div>
    )
}