import React from 'react';
import { Button } from 'antd';
import { useEffect } from 'react';
import './ValidationPopup.css';
import { useForm } from 'react-hook-form';
import Close from '../../icons/v2/Close.svg';
import Success from '../../icons/v2/Success.svg';
import Failure from '../../icons/v2/Failure.svg';

export function ValidationPopupComponent({
    closePopup,
    validationQuestion,
    submitFunction,
    title
}) {

    const [loading, setLoading] = React.useState(true);

    const [ inputValue, setInputValue ] = React.useState('');

    const handleChange = event => {
        setInputValue(event.target.value);
    
        console.log('value is:', event.target.value);
      };

    const backToHome = () => {
        closePopup();
    }

    return (
        <div className="popupval">
            <div className="popup_inner">
                <img src={Close} alt="closeIcon" className="closeIcon" onClick={closePopup} />
                <div className="updateAddressTitle">
                    {title}
                </div>
                <div className="valQuestion">{validationQuestion}</div>
                <input type="text" onChange={handleChange} value={inputValue}/>
                <div>
                    <Button type="primary" className="confirmButton" onClick={() => { submitFunction(inputValue) }}>Confirm</Button>
                </div>
            </div>
        </div>
    )
}