import React from 'react';
import './GlobalHeaders.css';
import Logo from '../../icons/logo.svg';
import {
    Button,
} from 'antd';
import { getPlatformBackend, getPlatformFrontend } from '../../utils/platform';

export function GlobalHeadersComponent({

}) {

    const hanldeBtnClick = (str) => {
        window.location.href = str;
    }

    return (
        <header className="globalHeadersContainer">
            <div className="globalHeaderLogo" onClick={() => hanldeBtnClick(getPlatformFrontend())}>
                <img src={Logo} alt="logoIcon" />
                <div className="globalHeaderLabel">QBot</div>
            </div>
            {/* <button class="navbar-toggler" data-bs-toggle="modal" data-bs-target="#MenuModal">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 17C20.3284 17 21 17.6716 21 18.5C21 19.3284 20.3284 20 19.5 20H4.5C3.67157 20 3 19.3284 3 18.5C3 17.6716 3.67157 17 4.5 17H19.5ZM19.5 10.5C20.3284 10.5 21 11.1716 21 12C21 12.8284 20.3284 13.5 19.5 13.5H4.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5H19.5ZM19.5 4C20.3284 4 21 4.67157 21 5.5C21 6.32843 20.3284 7 19.5 7H4.5C3.67157 7 3 6.32843 3 5.5C3 4.67157 3.67157 4 4.5 4H19.5Z" fill="white"/>
                </svg>					
            </button> */}
            <div className="globalHeaderBtnGroup" id="MainNavigation">
                <div className="headerButtonItemUnselected">
                    <Button
                        className="marketplaceBtn"
                        type="primary"
                        style={{ margin: "0.7rem" }}
                        onClick={() => hanldeBtnClick(getPlatformFrontend() + '/marketplace')}
                    >
                        Marketplace
                    </Button>
                </div>
                <div className="headerButtonItemSelected">
                    <Button
                        type="primary"
                        style={{ margin: "0.7rem" }}
                        onClick={() => hanldeBtnClick(getPlatformBackend() + '/api/discord/check')}
                    >
                        Dashboard
                    </Button>
                </div>
            </div>
        </header>
    )
}