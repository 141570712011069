import React from 'react';
import './AdminReleaseCalender.css';
import { Calendar, Badge } from 'antd';
import * as CommonApplicationHelper from '../../helpers/CommonApplicationHelper';

export function AdminReleaseCalenderComponent({
    releases
}) {

    let releaseDates = [];
    if (releases && releases.length > 0) {
        releaseDates = releases.map(release => {
            return new Date(release.release_date).toLocaleString().split(',')[0];
        });
    }

    const dateCellRender = (value) => {
        //console.log(value);

        const listData = [];

        if (releaseDates.indexOf(value.format('D.M.YYYY')) !== -1) {
            
            releaseDates.forEach((release, index) => {
                if (release === value.format('D.M.YYYY')) {
                    listData.push({
                        type: 'success',
                        content: releases[index].product_name,
                    });
                }
            });
            
        }
        //console.log(listData)
        
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content} />
                    </li>
                ))}
            </ul>
        );
    };

    const onPanelChange = (value, mode) => {
        console.log('upcomingDrops onPanelChange', value, mode);
    };

    return (
        <div>
            <Calendar
                dateCellRender={dateCellRender}
                //onPanelChange={onPanelChange}
                //onSelect={onSelect}
            />
        </div>
    );
}