import React from 'react';
import './ImpressumPage.css';
import {
    GlobalFootersComponent,
    GlobalHeadersComponent,
} from '../../components';

export function ImpressumPage(props) {

    return (
        <div>
            <GlobalHeadersComponent />
            <div className="impressumContainer">
                <div className="textContainer">
                    <h1>Impressum</h1>

                    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>Q Technologies GmbH<br />
                        Am Verschhubbahnhof 51<br />
                        47809 Krefeld</p>

                    <p>Handelsregister: HRB 18746<br />
                        Registergericht: Krefeld</p>

                    <p><strong>Vertreten durch:</strong><br />
                        Jakob Sonnen<br />
                        Mayurathan Navaratnarajah</p>

                    <h2>Kontakt</h2>
                    <p>E-Mail: qtechnologiesgmbh@gmail.com</p>

                    <h2>Redaktionell verantwortlich</h2>
                    <p>Q Technologies GmbH</p>

                    <h2>EU-Streitschlichtung</h2>
                    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
                </div>
            </div>
        </div>
    )
}