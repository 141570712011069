import React from 'react';
import { AddressFormComponent } from '../../components';
import './MarketplacePage.css';
import { PlanCardComponent } from '../../components/PlanCard';
import {
    GlobalFootersComponent,
    GlobalHeadersComponent,
} from '../../components';

export function MarketplacePage(props) {

    return (
        <div style={{ backgroundColor: '#ff0000' }}>
            <GlobalHeadersComponent />
            <div className="notFoundContainer">
                <div className="notFoundText">COMING SOON</div>
            </div>
        </div>
    )
}