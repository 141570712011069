import React from 'react';
import { Button } from 'antd';
import './AdminStores.css';

export function AdminStoresComponent({

}) {
    return (
        <div>
            <div className="adminStatHeader">
                <div className="adminStatHeaderLabel">Stores</div>
            </div>
        </div>
    )
}