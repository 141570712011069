import React from 'react';
import { useEffect } from 'react';
import { CreateAddressComponent, LogoLoaderComponent } from '../../components';
import { CreatePaymentComponent } from '../../components';
import { getUserDetailsTransition } from '../../utils/api';
import { Spin, Button } from 'antd';
import './SubscriptionPage.css';
import Download from '../../icons/download.svg';
import AnimationLogo from '../../icons/animationLogo.svg';
import checkout from '../../assets/hero-image-checkout.png';

export function SubscriptionPage({
    history
}) {
    const [loading, setLoading] = React.useState(true);
    const [showPopupAddress, setShowPopupAddress] = React.useState(false);
    const [showPopupPayment, setShowPopupPayment] = React.useState(false);
    const [customerId, setCustomerId] = React.useState('');
    const [userType, setUserType] = React.useState('');

    useEffect(() => {
        async function getData() {
            try {
                let data = await getUserDetailsTransition();
                setUserType(data.data.license_type);
                console.log("USER TYPE", userType)

                setLoading(false);
            } catch (err) {
                console.log(err);
                history.push("/");
                setLoading(false);
            }
        }
        getData();
    }, [])

    const updateCustomerId = (customerId) => {
        setCustomerId(customerId);
        console.log(customerId);
        //switch to payment popup
        setShowPopupAddress(false);
        setShowPopupPayment(true);
    }

    const togglePopupAddress = () => {
        if (!showPopupAddress) {
            setShowPopupAddress(true);
        } else {
            setShowPopupAddress(false);
        }
    }

    const togglePopupPayment = () => {
        if (!showPopupPayment) {
            setShowPopupPayment(true);
        } else {
            setShowPopupPayment(false);
        }
    }

    return loading ?
        <div className="subscriptionPageContainer">
            <LogoLoaderComponent></LogoLoaderComponent>
        </div> : (
            <div className="subscriptionPageContainer">
                <div className="heroTitle wow fadeInUp subPadding">QBot <span className="textBlue">2.0</span></div>
                <div className="heroDesc wow fadeInUp">Fill in your address and payment info to complete your subscription. Please use an IP address located in your address country.</div>
                <Button
                    className="subscribeBtn"
                    type="primary"
                    style={{ margin: "0.7rem" }}
                    icon={<img src={Download} alt="Download" className="downloadIcon" />}
                    onClick={togglePopupAddress.bind(this)}
                >
                    Upgrade to 2.0
                </Button>
                <img src={checkout} alt="CheckoutUI" className="checkoutUI" />
                {showPopupAddress ?
                    <CreateAddressComponent
                        closePopup={togglePopupAddress.bind(this)}
                        updateCustomerId={updateCustomerId}
                    />
                    : null
                }
                {showPopupPayment ?
                    <CreatePaymentComponent
                        closeCardPopup={togglePopupPayment.bind(this)}
                        customerId={customerId}
                        userType={userType}
                    />
                    : null
                }
            </div>
            /*
            <div>
                <AddressFormComponent type={"subscription"}></AddressFormComponent>
            </div>
            */
        );
}