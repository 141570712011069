import React from 'react';
import './TermsCancellationPage.css';
import {
    GlobalFootersComponent,
    GlobalHeadersComponent,
} from '../../components';

export function TermsCancellationPage(props) {

    return (
        <div>
            <GlobalHeadersComponent />
            <div className="privacyPolicyContainer">
                <div className="textPrivacyContainer">
                    <h1>Cancellation Policy</h1>
                    <p>You are not permitted to cancel your purchase. The right to cancel this contract and the 14-day withdrawal period expire when you expressly accept the Terms and Conditions by checking the relevant box before completing the purchase. You must check this box in order to proceed with the checkout process. You agree that we begin with the execution of the contract before the end of the withdrawal period and you confirm your knowledge that you lose the right of withdrawal by your agreement with the start of execution of the contract.</p>
                </div>
            </div>
        </div>
    )
}