import React, { useEffect } from 'react';
import './OrderHistory.css';
import { Table, Input, Spin, Button, Space } from 'antd';
import Search from '../../icons/search.svg';
import DetailGrey from '../../icons/v2/DetailGrey.svg';
import DetailBlue from '../../icons/v2/DetailBlue.svg';
import ArrowBack from '../../icons/v2/ArrowBack.svg';
import axios from 'axios';
import {
    getOrderStatisticsDetails,
    getAllCheckoutsUser
} from '../../utils/api';
import {
    DropdownComponent
} from '../Dropdown';
import {
    MobileProductCardComponent
} from '../MobileProductCard';
import { SearchOutlined } from '@ant-design/icons';
import { getPlatformBackend } from '../../utils/platform';

export function OrderHistoryComponent({
    orderData,
    user,
    licenseId
}) {
    /*
    orderData = {
        All: [],
        Year: [],
        Month: [],
        Week: [],
        Day: []
    }
    */
    let searchInput = React.createRef();
    console.log('orderComponentH orderData', orderData);
    const dropdownOption = [
        "All",
        "This Year",
        "This Month",
        "This Week",
        "Today"
    ];
    const dropdownMapping = {
        "All": "All",
        "This Year": "Year",
        "This Month": "Month",
        "This Week": "Week",
        "Today": "Day",
    }
    const dropdownDetailMapping = {
        "All": "all",
        "This Year": "year",
        "This Month": "month",
        "This Week": "week",
        "Today": "day",
    }

    const [selectedRange, setselectedRange] = React.useState(dropdownOption[0]);
    const [isShowDetail, setisShowDetail] = React.useState(false);
    const [selectedProduct, setselectedProduct] = React.useState({});
    const [orderDataDetails, setorderDataDetails] = React.useState([]);
    const [isLoading, setisLoading] = React.useState(false);
    const [searchText, setsearchText] = React.useState('');
    const [searchedColumn, setsearchedColumn] = React.useState('');
    const [checkoutData, setcheckoutData] = React.useState({ All: [], Year: [], Month: [], Week: [], Day: [] });


    const getAllCheckoutsUserData = async (user_id, license_id) => {
        let res = await getAllCheckoutsUser(user_id, license_id);
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getAllCheckoutsUserData", res);
            return res.data;
        } else {
            return { All: [], Year: [], Month: [], Week: [], Day: [] };
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await getAllCheckoutsUserData(user.id, licenseId);
            setcheckoutData(result);
        }

        fetchData().catch(error => {
            console.log("error", error);
        });
    }, []);

    console.log("orderComponentH checkoutData", checkoutData);

    const handleSetselectedRange = (data) => {
        console.log('handleSetselectedRange', data);
        setselectedRange(data);
    }

    const handleOrderClick = (id, product, store, totalCheckout, moneySpent, productImage, price, sku, pid) => {
        console.log('handleOrderClick', id);
        setselectedProduct({
            product,
            totalCheckout,
            store,
            moneySpent,
            productImage,
            price,
            sku,
            pid
        })
        setisShowDetail(true);
        setisLoading(true);
        getOrderStatisticsDetails(dropdownDetailMapping[selectedRange], user.id, licenseId, id);
    }

    const getOrderStatisticsDetails = async (timeRange, userId, license_id, productId) => {

        //console.log('orderComponentH orderStatisticsDetails', getPlatformBackend() + `/api/checkouts/${timeRange}/user/${userId}/product/${productId}`);
        // let orderStatisticsDetails = getOrderStatisticsDetails(dropdownDetailMapping[selectedRange], customerNumberId, selectedProductId);


        await axios.get(getPlatformBackend() + `/api/checkouts/${timeRange}/user/${userId}/license/${license_id}/product/${productId}`, {
            withCredentials: true
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log('orderComponentH orderInDetail', res.data);
                    setorderDataDetails(res.data);
                    setisLoading(false);
                } else {
                    console.log("Error", res.status);
                    setorderDataDetails([]);
                    setisLoading(false);
                }
            }).catch((error) => {
                console.log("error", error);
                setorderDataDetails([]);
                setisLoading(false);
            });

        // console.log('orderComponentH orderStatisticsDetails', orderStatisticsDetails);
        // setorderDataDetails(orderStatisticsDetails);
    }

    const handleBackClick = () => {
        setisShowDetail(false);
        setselectedProduct({});
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setsearchText(selectedKeys[0]);
                            setsearchedColumn(dataIndex);
                            //   this.setState({
                            //     searchText: selectedKeys[0],
                            //     searchedColumn: dataIndex,
                            //   });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#0D9FFF' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text => (
            text
        ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        // this.setState({
        //   searchText: selectedKeys[0],
        //   searchedColumn: dataIndex,
        // });
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
        // this.setState({ searchText: '' });
    };

    const getOrderLink = (store, storeRegion, email, orderNumber) => {
        switch (store.toLowerCase()) {
            case 'jdsports':
                /*
                if (storeRegion.toLowerCase() === 'jdsportsuk') {
                    return `https://data.smartagent.io/v1/jdsports/track-my-order?orderNumber=${orderNumber}&facia=jdsportsuk&emailAddress=${email}`;
                }
                */
                return `https://data.smartagent.io/v1/jdsports/track-my-order?orderNumber=${orderNumber}&facia=${storeRegion.toLowerCase()}&emailAddress=${email}`;
            case 'footpatrol':
                if (storeRegion.toLowerCase() === 'footpatrolgb') {
                    return `https://data.smartagent.io/v1/jdsports/track-my-order?orderNumber=${orderNumber}&facia=footpatrolgb&emailAddress=${email}`;
                } else if (storeRegion.toLowerCase() === 'footpatrolde') {
                    return `https://www.footpatrol.de/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
                } else if (storeRegion.toLowerCase() === 'footpatrolfr') {
                    return `https://www.footpatrol.fr/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
                } else if (storeRegion.toLowerCase() === 'footpatrolit') {
                    return `https://www.footpatrol.it/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
                } else if (storeRegion.toLowerCase() === 'footpatrolnl') {
                    return `https://www.footpatrol.nl/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
                } else if (storeRegion.toLowerCase() === 'footpatroldk') {
                    return `https://www.footpatrol.dk/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
                } else if (storeRegion.toLowerCase() === 'footpatrolse') {
                    return `https://www.footpatrol.se/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
                }
            case 'thehipstore':
                return `https://www.thehipstore.co.uk/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
            case 'size':
                return `https://data.smartagent.io/v1/jdsports/track-my-order?orderNumber=${orderNumber}&facia=${storeRegion.toLowerCase()}&emailAddress=${email}`;
            case 'jdsportsglobal':
                return `https://www.global.jdsports.com/track-my-order/?orderID=${orderNumber}&email=${email}&postcode=`;
            default:
                return null;
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: 'productPicture',
            render: (text) => {
                if (text === null || text === undefined || text === '') {
                    return (<div></div>)
                }
                return (
                    <img src={text} alt="chartIcon" className="tableProductIcon" style={{height: 50}}/>
                )
            },
            width: 50,
        },
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            /*
            //IT DOESNT LOAD IDK WHY????
            render: (text, record) => {
                console.log('tableColumn product', text, record);
                return (
                    <div className="tableColumnProduct">
                        <div className="tableProductTitle">{text}</div>
                    </div>
                    
                );
            },
            */
            ...getColumnSearchProps('product'),
            width: 200,
            render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
        },
        {
            title: 'STORE',
            dataIndex: 'store',
            width: 100,
            render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
        },
        {
            title: 'CHECKOUT',
            dataIndex: 'totalCheckout',
            sorter: {
                compare: (a, b) => a.totalCheckout - b.totalCheckout,
                multiple: 2,
            },
            render: (text) => {
                if (text === null || text === undefined || text === '') {
                    return (<div></div>)
                }
                return (
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text} Items</div>
                )
            },
            width: 100,
        },
        {
            title: 'MONEY SPENT',
            dataIndex: 'moneySpent',
            sorter: {
                compare: (a, b) => a.moneySpent - b.moneySpent,
                multiple: 1,
            },
            render: (text) => {
                console.log("MONEY SPENT", text)
                return (
                    <div className="itemSpent" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>
                )
            },
            width: 100,
        },
        {
            title: '',
            dataIndex: 'showDetails',
            key: 'x',
            render: (text, record) => {
                console.log('tableColumn x', text, record);
                if (!text) {
                    return (<div></div>)
                }
                return (

                    <img
                        src={DetailBlue}
                        alt="chartIcon"
                        className="orderItemIcon"
                        onClick={
                            () => handleOrderClick(
                                record.productId,
                                record.product,
                                record.store,
                                record.totalCheckout,
                                record.moneySpent,
                                record.productPicture,
                                record.price,
                                record.sku,
                                record.store_pid)
                        }
                    />


                    // <div>{record.key}</div>
                );
            },
            width: 50,
        },
    ];

    const data = [];
    // for (let i = 0; i < 26; i ++) {
    //     data.push({
    //         key: i,
    //         product: `Nike Kobe Vi Protro - Men Shoes ${Math.floor(Math.random() * 100)}`,
    //         releaseDate: '10/04/2019',
    //         totalCheckout: Math.floor(Math.random() * 100),
    //         moneySpent: Math.floor(Math.random() * 10000)
    //     });
    // }

    if (checkoutData[dropdownMapping[selectedRange]].length > 0) {
        checkoutData[dropdownMapping[selectedRange]].forEach((ele, index) => {
            const date = new Date(ele.creation);
            data.push({
                key: index,
                product: ele.product_name,
                productPicture: ele.product_image,
                productId: ele.product_id,
                //productImage: ele.product_image,
                //releaseDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                store: ele.store,
                totalCheckout: ele.checkouts,
                moneySpent: (Number(ele.checkouts) * Number(ele.price)).toFixed(2),
                price: ele.price,
                sku: ele.retailer_sku,
                store_pid: ele.store_pid,
                showDetails: true,
            });
        })

        let dummyCount = 4 - (data.length % 4);
        if (dummyCount === 4) dummyCount = 0;
        

        for (let i = 0; i < dummyCount; i++) {
            data.push({
                key: `dummy-${i}`,
                product: "",
                productPicture: "",
                productId: "",
                //productImage: ele.product_image,
                //releaseDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                store: "",
                totalCheckout: "",
                moneySpent: "",
                price: "",
                sku: "",
                store_pid: "",
                showDetails: false,
            });
        }
        /*
        data.push(
            Array(4 - (data.length % 4))
                .fill({})
                .map((dummy, index) => ({
                    key: `dummy-${index}`,
                    product: "",
                    productPicture: "",
                    productId: "",
                    //productImage: ele.product_image,
                    //releaseDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                    store: "",
                    totalCheckout: "",
                    moneySpent: "",
                    price: "",
                    sku: "",
                    store_pid: "",
                    showDetails: false,
                }))
        );
        */
    }
    console.log('orderHistory 911 data', data, selectedRange);

    const columnsDetails = [
        {
            title: 'ORDER NUMBER',
            dataIndex: 'orderNumber',
            width: 150,
            render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
        },
        {
            title: 'REGION',
            dataIndex: 'storeRegion',
            width: 105,
            render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
        },
        {
            title: 'SIZE',
            dataIndex: 'size',
            sorter: {
                compare: (a, b) => a.size - b.size,
                multiple: 3,
            },
            width: 55,
            render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
        },
        {
            title: 'DATE',
            dataIndex: 'checkoutDate',
            width: 140,
            render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            width: 220,
            render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>,
        },
        {
            title: '',
            dataIndex: 'orderCheckLink',
            key: 'x',
            render: (text, record) => {

                if (!text) {
                    return (<div></div>)
                }

                let orderLink = getOrderLink(record.store, record.storeRegion, record.email, record.orderNumber);
                return (
                    <a href={orderLink} target="_blank">
                        <img
                            src={DetailGrey}
                            alt="chartIcon"
                            className="orderItemIcon"
                        //onClick={window.open(orderLink, "_blank")}
                        />
                    </a>
                    // <div>{record.key}</div>
                );
            },
            width: 40,
        },
    ];
    const dataDetails = [];
    // for (let i = 0; i < 26; i ++) {
    //     dataDetails.push({
    //         key: i,
    //         product: `Nike Kobe Vi Protro - Men Shoes ${Math.floor(Math.random() * 100)}`,
    //         size: 'EU 10',
    //         date: '10/04/2019',
    //         price: Math.floor(Math.random() * 100)
    //     });
    // }
    if (orderDataDetails.length > 0) {
        orderDataDetails.forEach((ele, index) => {
            const date = new Date(ele.creation).toLocaleString();
            dataDetails.push({
                key: index,
                orderNumber: ele.order_number,
                size: ele.size,
                productImage: ele.product_image,
                checkoutDate: date,
                store: ele.store,
                price: ele.price,
                sku: ele.retailer_sku,
                store_pid: ele.store_pid,
                email: ele.order_email,
                storeRegion: ele.storeRegion,
                orderCheckLink: true
            });
        })
        let dummyCount = 6 - (dataDetails.length % 6);
        if (dummyCount === 6) dummyCount = 0;

        for (let i = 0; i < dummyCount; i++) {
            dataDetails.push({
                key: `dummy-${i}`,
                orderNumber: '',
                size: '',
                productImage: '',
                checkoutDate: '',
                store: '',
                price: '',
                sku: '',
                store_pid: '',
                email: '',
                storeRegion: '',
                orderCheckLink: false
            });
        }
    }

    console.log('orderHistoryData', dataDetails);

    const columnsMobile = [
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            render: (text, record) => {
                console.log('columnsMobile product', text, record);
                return (
                    <div className="mobileProductCardContainer">
                        <div className="mobileCardHeader">
                            <img src={record.productImage} alt="chartIcon" className="tableProductIcon" />
                            <div>
                                <div className="mobileProductTitle">{record.product}</div>
                                <div className="mobileProductLabel">{record.sku}</div>
                            </div>
                        </div>
                        <div>
                            <div className="mobileProductInfo">
                                <div className="mobileProductInfoLeft">Total Checkout</div>
                                <div className="mobileProductInfoRight">{`${record.totalCheckout} Items`}</div>
                            </div>
                            <div className="mobileProductInfo">
                                <div className="mobileProductInfoLeft">Money Spent</div>
                                <div className="mobileProductInfoRight">{`$${record.moneySpent}`}</div>
                            </div>
                        </div>
                        <div
                            className="mobileProductFooter"
                            onClick={
                                () => handleOrderClick(record.productId,
                                    record.product,
                                    record.releaseDate,
                                    record.totalCheckout,
                                    record.moneySpent,
                                    record.productImage,
                                    record.price,
                                    record.sku,
                                    record.store_pid)
                            }
                        >
                            View Details
                            <img src={DetailBlue} alt="chartIcon" className="detailBlueIcon" />
                        </div>
                    </div>
                );
            },
            ...getColumnSearchProps('product'),
        },
    ];

    const dataMobile = [];
    if (checkoutData[dropdownMapping[selectedRange]].length > 0) {
        checkoutData[dropdownMapping[selectedRange]].forEach((ele, index) => {
            const date = new Date(ele.creation);
            dataMobile.push({
                key: index,
                product: ele.product_name,
                productId: ele.product_id,
                productImage: ele.product_image,
                releaseDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                totalCheckout: ele.checkouts,
                moneySpent: (Number(ele.checkouts) * Number(ele.price)).toFixed(2),
                price: ele.price,
                sku: ele.retailer_sku,
            });
        })
    }

    const columnsMobileDetails = [
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            render: (text, record) => {
                console.log('columnsMobileDetails product', text, record);
                return (
                    <div className="mobileProductCardContainer">
                        <div className="mobileCardHeader">
                            <img src={record.productImage} alt="chartIcon" className="tableProductIcon" />
                            <div>
                                <div className="mobileProductTitle">{record.product}</div>
                                <div className="mobileProductLabel">{record.sku}</div>
                            </div>
                        </div>
                        <div>
                            <div className="mobileProductInfo">
                                <div className="mobileProductInfoLeft">Size</div>
                                <div className="mobileProductInfoRight">{record.size}</div>
                            </div>
                            <div className="mobileProductInfo">
                                <div className="mobileProductInfoLeft">Date</div>
                                <div className="mobileProductInfoRight">{record.releaseDate}</div>
                            </div>
                            <div className="mobileProductInfo">
                                <div className="mobileProductInfoLeft">Money Spent</div>
                                <div className="mobileProductInfoRight">{record.price}</div>
                            </div>
                        </div>
                    </div>
                );
            },
            // ...getColumnSearchProps('product'),
        },
    ];

    const dataMobileDetails = [];
    if (orderDataDetails.length > 0) {
        orderDataDetails.forEach((ele, index) => {
            const date = new Date(ele.creation);
            dataMobileDetails.push({
                key: index,
                product: ele.product_name,
                size: ele.size,
                productImage: ele.product_image,
                releaseDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
                price: ele.price,
                sku: ele.retailer_sku,
            });
        })
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    return (
        <div className="infoContainer orderHistoryContainer">
            {
                isShowDetail ?
                    <div className="orderStatisticDetails">
                        <div className="detailsBack" onClick={handleBackClick}>
                            <img src={ArrowBack} alt="chartIcon" className="orderItemIcon" />
                            Back
                        </div>
                        <div className="orderDetailsTop paymentHeader">
                            <div className="orderDetailsLeft">
                                <div className="orderDetailsImg">
                                    <img
                                        src={selectedProduct.productImage}
                                        alt='productImg'
                                    />
                                </div>
                                <div className="productCardContent orderDetailsContent">
                                    <div className="contentName">{selectedProduct.product}</div>
                                    <div className="contentColor">{selectedProduct.sku}</div>
                                    <div className="contentPrice">{selectedProduct.price}</div>
                                    <div className="contentPrice">{selectedProduct.store}, {selectedProduct.pid}</div>

                                </div>
                            </div>
                            <div className="productCardBottom orderDetailsRight">
                                <div className="productCardBottomLeft orderDetailCard">
                                    <div className="productLabel">
                                        Total Checkout
                                    </div>
                                    <div className="productContent">
                                        {`${selectedProduct.totalCheckout} Items`}
                                    </div>
                                </div>
                                <div className="productCardBottomRight orderDetailCard">
                                    <div className="productLabel">
                                        Money Spent
                                    </div>
                                    <div className="productContent">
                                        {`$${selectedProduct.moneySpent}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="orderHistoryTable">
                            {
                                isLoading ?
                                    <div className="orderHistorySpinner">
                                        <Spin size="large" />
                                    </div> :
                                    <div>
                                        <div className="desktopView">
                                            <Table columns={columnsDetails} dataSource={dataDetails} tableLayout="fixed" pagination={{ defaultPageSize: 6, showSizeChanger: false }} onChange={onChange} id="userOrderSingle" />
                                        </div>
                                        <div className="mobileView mobileOrderDetailTable">
                                            <Table columns={columnsMobileDetails} dataSource={dataMobileDetails} onChange={onChange} showHeader={false} />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div> :
                    <div>
                        <div className="desktopView">
                            <div className="orderStatisticTop paymentHeader">
                                <div className="infoHeader orderStatisticHeader">Order History</div>
                                <div className="orderStatisticSelection">
                                    {/* <div className="orderSearchHolder">
                                    <Input
                                        className="orderSearch"
                                        placeholder="Search shoe, colour, brand"
                                        prefix={<img
                                            src={Search}
                                            alt="chartIcon"
                                            className=""
                                        />} 
                                    />
                                    
                                </div> */}
                                    <DropdownComponent
                                        options={dropdownOption}
                                        selectedRange={selectedRange}
                                        handleSetselectedRange={handleSetselectedRange}
                                    />
                                </div>
                            </div>
                            <div className="orderHistoryTable">
                                <Table columns={columns} dataSource={data} tableLayout="fixed" pagination={{ defaultPageSize: 4, showSizeChanger: false }} onChange={onChange} id="userOrderTotal" />
                            </div>
                        </div>
                        <div className="mobileView orderHistoryTable">
                            {/* <MobileProductCardComponent /> */}
                            {
                                isShowDetail ?
                                    '' :
                                    <Table columns={columnsMobile} dataSource={dataMobile} onChange={onChange} showHeader={false} />
                            }

                        </div>
                    </div>
            }
            {/* {checkouts.map((checkout) => (
                <li key={checkout.id}>{checkout.product_name}, {checkout.price}, {checkout.size}, {checkout.store}, {checkout.order_number}</li>
            ))} */}
        </div>
    )
}