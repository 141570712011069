import React from 'react';
import { useEffect } from 'react';
import {
    getUserDetailsUser,
    getCheckouts,
    getReleases,
    getCheckoutsYear,
    getUserInfo,
    getStripeCustomer,
    getLicense,
    postGetCardDetails,
    postGetCustomerInfo,
    postGetUpcomingSubscription,
    postGetSubscriptionPayment,
    getOrderStatistics
} from '../../utils/api';
import {
    InfoComponent,
    OrderHistoryComponent,
    OrderStatisticsComponent,
    ReleaseCalenderComponent,
    HeadersComponent,
    PaymentComponent,
    CardDetailsComponent,
    LogoLoaderComponent
} from '../../components';
import { Spin, Button } from 'antd';
import './DashboardPage.css';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
import * as CommonApplicationHelper from '../../helpers/CommonApplicationHelper';
import CalendarWhite from '../../icons/v2/CalendarWhite.svg';

export function DashboardPage({
    history
}) {

    const [user, setUser] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    //const [checkoutArray, setCheckoutArray] = React.useState([]);
    const [releaseArray, setReleaseArray] = React.useState([]);
    const [checkoutYearArray, setCheckoutYearArray] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState({});
    const [cardDetail, setCardDetail] = React.useState({});
    const [customerAddress, setCustomerAddress] = React.useState({
        "address": {
            "city": "",
            "country": "",
            "line1": "",
            "line2": "",
            "postal_code": "",
            "state": ""
        },
        "email": "",
        "name": ""
    });
    const [customerId, setCustomerId] = React.useState("");
    const [paymentMethod, setPaymentMethod] = React.useState("");
    const [upcomingCharge, setUpcomingCharge] = React.useState("");
    const [subPayment, setsubPayment] = React.useState("");
    const [selectedTitle, setSelectedTitle] = React.useState("manageLicense");
    const [orderStatistics, setorderStatistics] = React.useState({});
    const [customerNumberId, setcustomerNumberId] = React.useState("");
    const [isShowMobileMenu, setIsShowMobileMenu] = React.useState(false);
    const [isShowCalendar, setIsShowCalendar] = React.useState(false);

    const handleSelectTitle = (str) => {
        console.log('handleSelectTitle');
        setSelectedTitle(str);
        console.log('selectedTitle', selectedTitle);
    }

    /*
    const updateCustomerAddress = async (customerId) => {
        console.log('updateCustomerAddress');
        let customerInfo = await postGetCustomerInfo(customerId);
        setCustomerAddress(customerInfo.data);
    }
    */

    const handleCalendarClick = () => {
        if (isShowCalendar) {
            setIsShowCalendar(false);
        } else {
            setIsShowCalendar(true);
            setIsShowMobileMenu(false);
        }
    }

    const handleReload = () => {
        window.location.reload();
    }

    useEffect(() => {
        async function getData() {
            try {
                let userData = await getUserDetailsUser();
                if (userData === null || userData === undefined || userData.status !== 200) {
                    userData.data = {};
                }
                setUser(userData.data);

                let releases = await getReleases();
                setReleaseArray(releases.data);
                let userInfo = await getUserInfo(userData.data.id);
                if (userInfo === null || userInfo === undefined || userInfo.status !== 200) {
                    userInfo.data = {};
                }
                setUserInfo(userInfo.data);
                let stripeCustomer = await getStripeCustomer(userData.data.id);
                if (stripeCustomer === null || stripeCustomer === undefined || stripeCustomer.status !== 200) {
                    stripeCustomer.data = { "customer_id": "" };
                }
                setCustomerId(stripeCustomer.data.customer_id);

                setLoading(false);
            } catch (err) {
                console.log(err);
                history.push("/");
                setLoading(false);
            }
        }
        getData().catch(error => {
            console.log("error", error);
        });
    }, [])

    const dateList = CommonApplicationHelper.filterReleaseDate(releaseArray);

    if (!loading) {
        return (
            <div className="dashboardContainer">
                <HeadersComponent
                    info={userInfo}
                    selectedTitle={selectedTitle}
                    handleSelectTitle={handleSelectTitle}
                    isShowMobileMenu={isShowMobileMenu}
                    setIsShowMobileMenu={setIsShowMobileMenu}
                    setIsShowCalendar={setIsShowCalendar}
                />
                {
                    selectedTitle === 'manageLicense' ?
                        <div className={isShowMobileMenu ? "container backgroundBlur" : "container"}>
                            <div className="containerLeft">
                                <InfoComponent
                                    info={userInfo}
                                    customerId={customerId}

                                    handleReload={handleReload}
                                />
                                <PaymentComponent
                                    customerId={customerId}
                                />
                            </div>
                            <div className="containerRight">
                                <CardDetailsComponent
                                    cardDetail={cardDetail}
                                    address={customerAddress}
                                    customerId={customerId}
                                    licenseType={userInfo.license_type}

                                    handleReload={handleReload}
                                />
                            </div>
                        </div>
                        :
                        <>
                            <div className="headerButtonItemSelected width100 mobileView">
                                <Button
                                    type="primary"
                                    style={{ margin: "0.7rem" }}
                                    onClick={handleCalendarClick}
                                    icon={<img src={CalendarWhite} alt="Calendar" className="headerIcon" />}
                                >
                                    View Calendar
                                </Button>
                            </div>
                            <div className={isShowCalendar ? "container backgroundBlur" : "container"}>
                                <div className="containerLeft">
                                    <OrderStatisticsComponent
                                        user={user}
                                        orderData={orderStatistics}
                                        licenseId={userInfo.license_id}
                                    />
                                    <OrderHistoryComponent
                                        orderData={orderStatistics}
                                        user={user}
                                        licenseId={userInfo.license_id}
                                    />
                                </div>
                                <div className="containerRight desktopView">
                                    <ReleaseCalenderComponent
                                        releases={dateList}
                                        releaseArray={releaseArray}
                                        customerNumberId={user.id}
                                        licenseId={userInfo.license_id}
                                    />
                                </div>
                            </div>
                        </>

                }
                {
                    isShowCalendar ?
                        <div className="calendarMobileMenu">
                            <ReleaseCalenderComponent
                                releases={dateList}
                                releaseArray={releaseArray}
                                customerNumberId={user.id}
                            />
                        </div> : ''
                }
            </div>
        )
    } else {
        return (
            <LogoLoaderComponent></LogoLoaderComponent>
        )
    }
}