import React from 'react';
import './GlobalFooters.css';
import Logo from '../../icons/logo.svg';

export function GlobalFootersComponent({

}) {

    return (
        <footer className="globalFootersContainer">
            <div className="footerInnerContainer">
                <div className="globalFootersContainerTop">
                    <div className="globalFooterLogo">
                        <img src={Logo} alt="logoIcon" />
                        <div className="globalFooterLabel">QBot</div>
                    </div>
                    <div className="globalFooterLinks">
                        <a href="/impressum">Impressum</a>
                        <a href="/privacypolicy">Privacy Policy</a>
                        <a href="/terms">Terms & Conditions</a>
                        <a href="/cancellationpolicy" >Cancellation Policy</a>
                    </div>
                    <div className="socialMedia">
                        <a href="https://twitter.com/QBotaio">
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                    <path d="M31.534 17.113C32.51 16.42 33.331 15.555 34 14.559V14.558C33.107 14.949 32.157 15.209 31.165 15.335C32.185 14.726 32.964 13.769 33.33 12.616C32.379 13.183 31.329 13.583 30.21 13.807C29.307 12.845 28.02 12.25 26.616 12.25C23.892 12.25 21.699 14.461 21.699 17.171C21.699 17.561 21.732 17.936 21.813 18.293C17.723 18.093 14.103 16.133 11.671 13.146C11.247 13.883 10.997 14.726 10.997 15.633C10.997 17.337 11.874 18.847 13.183 19.722C12.392 19.707 11.617 19.477 10.96 19.116V19.17C10.96 21.561 12.665 23.547 14.902 24.005C14.501 24.115 14.065 24.167 13.612 24.167C13.297 24.167 12.979 24.149 12.681 24.083C13.318 26.031 15.128 27.464 17.278 27.511C15.604 28.82 13.478 29.609 11.177 29.609C10.774 29.609 10.387 29.591 10 29.542C12.18 30.947 14.762 31.75 17.548 31.75C26.231 31.75 31.89 24.506 31.534 17.113Z" fill="#969CB0"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="24" height="24" fill="white" transform="translate(10 10)"/>
                                    </clipPath>
                                </defs>
                            </svg>							
                        </a>
                    </div>
                </div>
                <div className="globalFootersContainerBottom">
                    <div className="copyright">
                        <p className="mb0 body-2">©2021-2023 Q Technologies GmbH</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}