import React, { useEffect } from 'react';
import { ProductCardComponent } from '../ProductCard';
import './AdminProducts.css';
import { Button, Pagination } from 'antd';
import { getCheckoutsProduct } from '../../utils/api';
import Refresh from '../../icons/v2/Refresh.svg';

export function AdminProductsComponent({
}) {

    const pageSize = 3;

    const [data, setData] = React.useState([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [minIndex, setMinIndex] = React.useState(0);
    const [maxIndex, setMaxIndex] = React.useState(0);

    //console.log("DATA LENGTH", data.length);
    //console.log(data.slice(0, 9));

    const getCheckoutsProductData = async () => {
        let res = await getCheckoutsProduct();
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getCheckoutsProductData", res);
            return res.data;
        } else {
            return [];
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const result = await getCheckoutsProductData();
            setData(result);
        }


        fetchData().catch(error => {
            console.log("error", error);
        });
        setMinIndex(0);
        setMaxIndex(9);
    }, []);

    const refresh = async () => {
        const result = await getCheckoutsProductData();
        console.log("result resfresh", result);
        setData(result);
        setMinIndex(0);
        setMaxIndex(9);
    }


    const handleChange = (value) => {
        setCurrentPage(value);
        if (value <= 1) {
            setMinIndex(0);
            setMaxIndex(9);
        } else {
            setMinIndex(maxIndex);
            setMaxIndex(value * 9);

        }
    }

    //render al items from productsArray in a ProductCardComponent
    /*
    const productCards = productsArray.map((product, index) => {
        return (
            <div>
                {
                    data &&
                    data.length > 0 &&
                    data.slice(minIndex, maxIndex).map(val => (
                        <ProductCardComponent
                            key={index}
                            productStore={product.store}
                            productName={product.product_name}
                            productPrice={product.product_price}
                            productImage={product.product_image}
                            retailerSku={product.retailer_sku}
                            totalCheckouts={product.totalCheckouts}
                            successCheckouts={product.successCheckouts}
                            failedCheckouts={product.failedCheckouts}
                        />
                    ))
                }
            </div>
        )
    })
    <Button type="primary" className="refreshButton" onClick={refresh}>Bla</Button>
    */


    //console.log(products);
    return (
        <div>
            <div className="adminStatHeader test">
                <div className="headerProducts">
                    <div className="adminStatHeaderLabel">Products</div>
                    <img src={Refresh} alt="refreshIcon" className="refreshIcon" onClick={refresh}/>
                </div>
                <div className="productCards">
                    {
                        data &&
                        data.length > 0 &&
                        data.slice(minIndex, maxIndex).map((val, index) => (
                            <ProductCardComponent
                                key={index}
                                productStore={val.store}
                                productName={val.product_name}
                                productPrice={val.product_price}
                                productImage={val.product_image}
                                retailerSku={val.retailer_sku}
                                totalCheckouts={val.totalCheckouts}
                                successCheckouts={val.successCheckouts}
                                failedCheckouts={val.failedCheckouts}
                            />
                        ))
                    }
                </div>
                <div className="pagi">
                    <Pagination defaultCurrent={1} current={currentPage} total={data.length} defaultPageSize={9} onChange={handleChange} />
                </div>
            </div>
        </div>

    )
}