import React from 'react';
import { useEffect } from 'react';
import { getUserDetailsAdmin, getReleases, getAllLicensesUser, getAllLicensesHistory, getCheckoutsProduct } from '../../utils/api';
import { Button, Divider, Avatar, Spin } from 'antd';
import {
    AdminStatisticsComponent,
    AdminProductsComponent,
    AdminReleasesComponent,
    AdminLicensesComponent,
    AdminPaymentsComponent,
    AdminStoresComponent,
    AdminLogsComponent,
    AdminLicenseHistoryComponent,
} from '../../components';
import './AdminDashboardPage.css';
import Logo from '../../icons/logo.svg';
import ArrowDown from '../../icons/arrow down.svg';

export function AdminDashboardPage({
    history
}) {
    const [admin, setAdmin] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [showStatistics, setShowStatistics] = React.useState(false);
    const [showProducts, setShowProducts] = React.useState(false);
    const [showReleases, setShowReleases] = React.useState(false);
    const [showLicenses, setShowLicenses] = React.useState(false);
    const [showLicenseHistory, setShowLicenseHistory] = React.useState(false);
    const [showPayments, setShowPayments] = React.useState(false);
    const [showStores, setShowStores] = React.useState(false);
    const [showLogs, setShowLogs] = React.useState(false);

    //const [checkoutAllArray, setCheckoutAllArray] = React.useState([]);
    //const [checkoutAllYearArray, setCheckoutAllYearArray] = React.useState([]);
    //const [checkoutAllMonthArray, setCheckoutAllMonthArray] = React.useState([]);
    //const [checkoutAllWeekArray, setCheckoutAllWeekArray] = React.useState([]);
    //const [checkoutAllDayArray, setCheckoutAllDayArray] = React.useState([]);
    //const [releaseArray, setReleaseArray] = React.useState([]);
    //const [licenseUserArray, setLicenseUserArray] = React.useState([]);
    //const [licenseHistoryArray, setLicenseHistoryArray] = React.useState([]);
    //const [checkoutProductArray, setCheckoutProductArray] = React.useState([]);
    const [isShowDropdown, setisShowDropdown] = React.useState(false);

    const handleDropdownClick = () => {
        if (isShowDropdown) {
            setisShowDropdown(false);
        } else {
            setisShowDropdown(true);
        }
    }


    const onStatisticsButtonClick = () => {
        setShowStatistics(true);
        setShowProducts(false);
        setShowReleases(false);
        setShowLicenses(false);
        setShowPayments(false);
        setShowStores(false);
        setShowLogs(false);
        setShowLicenseHistory(false);
    }

    const onProductsButtonClick = () => {
        setShowStatistics(false);
        setShowProducts(true);
        setShowReleases(false);
        setShowLicenses(false);
        setShowPayments(false);
        setShowStores(false);
        setShowLogs(false);
        setShowLicenseHistory(false);
    }

    const onReleasesButtonClick = () => {
        setShowStatistics(false);
        setShowProducts(false);
        setShowReleases(true);
        setShowLicenses(false);
        setShowPayments(false);
        setShowStores(false);
        setShowLogs(false);
        setShowLicenseHistory(false);
    }

    const onLicensesButtonClick = () => {
        setShowStatistics(false);
        setShowProducts(false);
        setShowReleases(false);
        setShowLicenses(true);
        setShowPayments(false);
        setShowStores(false);
        setShowLogs(false);
        setShowLicenseHistory(false);
    }

    const onLicenseHistoryButtonClick = () => {
        setShowStatistics(false);
        setShowProducts(false);
        setShowReleases(false);
        setShowLicenses(false);
        setShowLicenseHistory(true);
        setShowPayments(false);
        setShowStores(false);
        setShowLogs(false);
    }

    const onPaymentsButtonClick = () => {
        setShowStatistics(false);
        setShowProducts(false);
        setShowReleases(false);
        setShowLicenses(false);
        setShowPayments(true);
        setShowStores(false);
        setShowLogs(false);
        setShowLicenseHistory(false);
    }

    const onStoresButtonClick = () => {
        setShowStatistics(false);
        setShowProducts(false);
        setShowReleases(false);
        setShowLicenses(false);
        setShowPayments(false);
        setShowStores(true);
        setShowLogs(false);
        setShowLicenseHistory(false);
    }

    const onLogsButtonClick = () => {
        setShowStatistics(false);
        setShowProducts(false);
        setShowReleases(false);
        setShowLicenses(false);
        setShowPayments(false);
        setShowStores(false);
        setShowLogs(true);
        setShowLicenseHistory(false);
    }

    /*
    const getUserDetails = async () => {
        let res = await getUserDetailsAdmin();
        if (res === null || res === undefined || res.status !== 200) {
            history.push('/');
        }
        setLoading(false);
    }

    getUserDetails().catch(error => {
        console.log("error getUserDetails", error);
    });
    */

    useEffect(() => {
        async function getData() {
            try {
                let res = await getUserDetailsAdmin();
                setAdmin(res.data);
                if (res === null || res === undefined || res.status !== 200) {
                    history.push('/');
                } else {
                    setLoading(false);
                }


            } catch (err) {
                console.log(err);
                history.push("/");
                setLoading(false);
            }
        }
        getData();
        setShowStatistics(true);
    }, [])


    if (!loading) {
        return (
            <div className="headerOutContainer">
                <div className="headerContainer">
                    <div className="logoIcon unselectable">
                        <img src={Logo} alt="logoIcon" />
                    </div>
                    <Divider type="vertical" className="headerIconDivider" />
                    <div className={showStatistics ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onStatisticsButtonClick} style={{ margin: "0.1rem" }}>Statistics</Button>
                    </div>
                    <div className={showProducts ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onProductsButtonClick} style={{ margin: "0.1rem" }}>Products</Button>
                    </div>
                    <div className={showReleases ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onReleasesButtonClick} style={{ margin: "0.1rem" }}>Releases</Button>
                    </div>
                    <div className={showLicenses ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onLicensesButtonClick} style={{ margin: "0.1rem" }}>Licences</Button>
                    </div>
                    <div className={showLicenseHistory ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onLicenseHistoryButtonClick} style={{ margin: "0.1rem" }}>LicenseHistory</Button>
                    </div>
                    <div className={showPayments ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onPaymentsButtonClick} style={{ margin: "0.1rem" }}>Payments</Button>
                    </div>
                    <div className={showStores ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onStoresButtonClick} style={{ margin: "0.1rem" }}>Stores</Button>
                    </div>
                    <div className={showLogs ? "headerButtonItemSelected" : "headerButtonItemUnselected"}>
                        <Button type="primary" onClick={onLogsButtonClick} style={{ margin: "0.1rem" }}>Logs</Button>
                    </div>
                    <div className="headerRightSection">
                        <div className="headerMobileAvatar">
                            <Avatar
                                src=""
                                className="userAvatar unselectable"
                                alt="User"
                            />
                        </div>
                        <div className="headerName">
                            Audrey Ramsey
                        </div>
                        <img
                            src={ArrowDown}
                            alt="arrowDownIcon"
                            className={isShowDropdown ? 'adminArrowUpIcon' : 'adminArrowDownIcon'}
                            onClick={handleDropdownClick}
                        />
                    </div>
                </div>
                {showStatistics ?
                    <AdminStatisticsComponent /> :
                    null
                }
                {showProducts ?
                    <AdminProductsComponent /> :
                    null
                }
                {showReleases ?
                    <AdminReleasesComponent /> :
                    null
                }
                {showLicenses ?
                    <AdminLicensesComponent adminId={admin.id} /> :
                    null
                }
                {showLicenseHistory ?
                    <AdminLicenseHistoryComponent /> :
                    null
                }
                {showPayments ?
                    <AdminPaymentsComponent /> : null
                }
                {showStores ?
                    <AdminStoresComponent /> : null
                }
                {showLogs ?
                    <AdminLogsComponent /> : null
                }
            </div>
        )

    } else {
        return (
            <div className="dashboardSpinner">
                <Spin size="large" />
            </div>
        )
    }
}