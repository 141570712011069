import React from 'react';
import './NotFoundPage.css';
import {
    GlobalFootersComponent,
    GlobalHeadersComponent,
} from '../../components';

export function NotFoundPage(props) {

    return (
        <div style={{ backgroundColor: '#ff0000' }}>
            <GlobalHeadersComponent />
            <div className="notFoundContainer">
                <div className="notFoundText">404 - Not Found</div>
            </div>
        </div>
    )
}