import React from 'react';
import './Dropdown.css';
import Calendar from '../../icons/v2/Calendar.svg';
import ArrowDown from '../../icons/arrow down.svg';

export function DropdownComponent({
    options,
    selectedRange,
    handleSetselectedRange
}) {
    console.log('selectedRange', selectedRange);

    const [isShowDropdown, setisShowDropdown] = React.useState(false);

    const handleDropdownClick = () => {
        if (isShowDropdown) {
            setisShowDropdown(false);
        } else {
            setisShowDropdown(true);
        }
    }

    const handleDropdownItemClick = (data) => {
        handleDropdownClick();
        handleSetselectedRange(data);
    }

    return (
        <div className="dropdownContainer">
            <div
                className={isShowDropdown ? "dropdwonLabel shadowBox" : "dropdwonLabel"}
                onClick={handleDropdownClick}
            >
                <div className="dropdownIcon">
                    <img src={Calendar} alt="calendarIcon" className="calendarIcom"/>
                </div>
                <div className="dropdownSelected">
                    {selectedRange}
                </div>
                <img
                    src={ArrowDown}
                    alt="arrowDownIcon"
                    className={isShowDropdown ? 'arrowUpIcon' : 'arrowDownIcon'}
                />
            </div>
            {
                isShowDropdown ?
                <div className="optionSelection shadowBox">
                    {
                        options.map((ele, index) => {
                            return (
                                <div
                                    className="optionItems"
                                    key={index}
                                    onClick={() => handleDropdownItemClick(ele)}
                                >
                                    {ele}
                                </div>
                            )
                        })
                    }
                </div> : ''
            }
        </div>
    )
}