import React from 'react';
import './LogoLoader.css';
import AnimationLogo from '../../icons/animationLogo.svg';

export function LogoLoaderComponent(
) {

    return (
        <div className="preloader">
            <div className="loading">
                <img src={AnimationLogo} alt="animationLogo" className="animationLogo" />
                <div className="progress-wrapper">
                    <div className="progress">
                    </div>
                </div>
            </div>
        </div>
    );
}