import React, { useEffect } from 'react';
import './ReleaseCalender.css';
import { Calendar, Badge } from 'antd';
import {
    ReleaseProductCardComponent
} from '../ReleaseProductCard';
import * as CommonApplicationHelper from '../../helpers/CommonApplicationHelper';
import { getReleases } from '../../utils/api';
import moment from 'moment';
import CalenderRight from '../../icons/v2/calenderRight.svg';


export function ReleaseCalenderComponent({
    releases,
    releaseArray,
    customerNumberId,
    licenseId
}) {

    const [data, setData] = React.useState([]);
    const [releaseList, setReleaseList] = React.useState([]);
    //const [filteredReleaseData, setFilteredReleaseData] = React.useState([]);

    const currentDate = new Date();
    const updatedCurrentDate = `${currentDate.getMonth() +
        1}.${currentDate.getDate()}.${currentDate.getFullYear()}`
    const [selectedDate, setselectedDate] = React.useState(updatedCurrentDate);

    const getReleaseData = async () => {
        let res = await getReleases();
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getReleaseData", res);
            return res.data;
        } else {
            return [];
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await getReleaseData();
            setData(result);
            const dateList = CommonApplicationHelper.filterReleaseDate(result);
            setReleaseList(dateList);
            /*
            const filReleaseData = CommonApplicationHelper.filterUpcomingProducts(
                result,
                selectedDate
            );
            */
            //console.log("ADsyxc", filReleaseData);
            //setFilteredReleaseData(filReleaseData);
        }

        fetchData().catch(error => {
            console.log("error", error);
        });
    }, [])


    const onPanelChange = (value, mode) => {
        console.log('upcomingDrops onPanelChange', value, mode);
    };

    const onSelect = (date) => {
        console.log('upcomingDrops onSelect', date, date._d);
        const dateData = new Date(date._d);
        const updatedDate = `${dateData.getMonth() +
            1}.${dateData.getDate()}.${dateData.getFullYear()}`;
        console.log('upcomingDrops updatedDate', updatedDate);
        setselectedDate(updatedDate);
    };

    // const renderProductList = (arr: UpcomingProductData[]) => {
    //     const { changeLogData } = this.props;
    //     const { selectedDate } = this.state;
    //     // console.log('renderProductList', changeLogData);
    //     if (Object.keys(changeLogData).length !== 0) {
    //         const filteredReleaseData = CommonApplicationHelper.filterUpcomingProducts(
    //             changeLogData.releases,
    //             selectedDate
    //         );
    //         console.log('filteredReleaseData', filteredReleaseData);
    //         return (
    //             <div className="upcomingProductList">
    //                 <div className="upcomingProductListTitle unselectable">
    //                     {`Show ${Object.keys(filteredReleaseData).length} product upcoming`}
    //                 </div>
    //                 {filteredReleaseData &&
    //                     Object.keys(filteredReleaseData) &&
    //                     Object.keys(filteredReleaseData).map(key => {
    //                         return (
    //                             <div className="upcomingProductItem unselectable" key={key}>
    //                                 <div className="productItemImg unselectable">
    //                                     <img
    //                                         src={changeLogData.releases[key].productImg}
    //                                         alt={changeLogData.releases[key].productName}
    //                                     />
    //                                 </div>
    //                                 <div className="productItemInfo unselectable">
    //                                     <div className="productItemTitle unselectable">
    //                                         {changeLogData.releases[key].productName}
    //                                     </div>
    //                                     <div className="productItemSku unselectable">{'sku'}</div>
    //                                     <div className="productItemPrice unselectable">
    //                                         {changeLogData.releases[key].productPrice}
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         );
    //                     })}
    //             </div>
    //         );
    //     }
    //     return <></>;
    // };

    const getListData = (value) => {
        let listData;
        // console.log('getListData productDateList', this.props.productDateList );
        // console.log('getListData', value.month(), value.date(), value.year());
        // console.log('getListData', typeof value.format('M.D.YYYY'), this.state.releaseDate);
        // console.log('getListData checker', value.format('M.D.YYYY').indexOf(this.state.releaseDate));
        // console.log('getListData productDateList', productDateList, value.format('M.D.YYYY'));
        if (releaseList[value.format('M.D.YYYY')] !== undefined) {
            listData = [{ type: 'success', content: '' }];
        }
        // switch (value.date()) {
        //     case 8:
        //         listData = [{ type: 'success', content: '' }];
        //         break;
        //     case 10:
        //         listData = [{ type: 'success', content: '' }];
        //         break;
        //     case 15:
        //         listData = [{ type: 'success', content: '' }];
        //         break;
        //     default:
        // }
        return listData || [];
    };

    const dateCellRender = (value) => {
        // console.log('dateCellRender', value);
        console.log('dateCellRender releases', data);
        if (Object.keys(releaseList).length > 0) {
            const listData = getListData(value);
            return (
                <ul className="events listTable">
                    {listData.map(item => (
                        <li key={item.content} className="cellItem">
                            <Badge status={item.type} text="" />
                        </li>
                    ))}
                </ul>
            );
        }
    };

    const renderProductList = (filteredReleaseData) => {
        /*
        await axios.get(getPlatformBackend() + `/api/checkouts/overview/user/${customerId}/product/${productId}`, {
            withCredentials: true
        })
            .then((res) => {
                console.log('calendar product', res.data);
                setcheckoutAmout(res.data.checkouts);
                setmoneySpentAmount(res.data.moneySpent);
            }).catch((error) => {
                console.log('error', error);
            });
        */
        
        return (
            <>
                {
                    filteredReleaseData.map((ele) => {

                        let dateNow = new Date();
                        let releaseDate = new Date(ele.release_date);

                        let showPast = releaseDate > dateNow ? false : true;


                        return (
                            <ReleaseProductCardComponent
                                productData={ele}
                                customerNumberId={customerNumberId}
                                isShowPast={showPast}
                                licenseId={licenseId}
                            />
                        );
                    })
                }
            </>
        )
    }

    console.log('renderProductList selectedDate', selectedDate);

    const filteredReleaseData = CommonApplicationHelper.filterUpcomingProducts(
        data,
        selectedDate
    );
    console.log('renderProductList filteredReleaseData', filteredReleaseData);

    const headerRender = ({ value, onChange }) => {
        console.log('VALLLL', value);

        return (
            <div className="calenderHeader">
                <img src={CalenderRight} alt="calenderIcon" className="calenderIcon unselectable rotateCalenderIcon" onClick={() => onChange(moment(value).subtract(1, 'M'))} />
                {/*<div onClick={() => onChange(moment(value).subtract(1, "M"))}>Links</div>*/}
                <div className="dateText">
                    {moment(value).format('MMMM')} {moment(value).format('YYYY')}
                </div>
                {/*<div onClick={() => onChange(moment(value).add(1, "M"))}>Rechts</div>*/}
                <img src={CalenderRight} alt="calenderIcon" className="calenderIcon unselectable" onClick={() => onChange(moment(value).add(1, 'M'))} />
            </div>
        );
    };

    return (
        <div className="releaseCalenderContainer calendarContainer">
            <h1 className="infoHeader">Release Calender</h1>
            <Calendar
                dateCellRender={dateCellRender}
                fullscreen={false}
                onPanelChange={onPanelChange}
                onSelect={onSelect}
                headerRender={headerRender}
            />
            <div className="selectedDate">
                <div className="selectedDateInner">
                    <div className="selectedDateInnerLabel">{selectedDate}</div>
                    <div className="selectedDateInnerAmount">{`${filteredReleaseData.length} Products Release`}</div>
                </div>
            </div>
            <div className="productBody">
                {renderProductList(filteredReleaseData)}
            </div>
            {/* <ReleaseProductCardComponent /> */}
            {/* {releases.map((release) => (
                <li key={release.id}>{release.release_date}, {release.store}, {release.retailer_sku}, {release.store_pid}, {release.product_name}, {release.product_price}, {release.product_image}, {release.product_link}</li>
            ))} */}
        </div>
    )
}