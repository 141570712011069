import React, { useEffect } from 'react';
import { Button } from 'antd';
import './AdminReleases.css';
import { AdminReleaseCalenderComponent } from '../AdminReleaseCalender';
import { CreateReleaseComponent } from '../CreateRelease';
import { getReleases } from '../../utils/api';

export function AdminReleasesComponent({
}) {

    const [ createReleasePopup, setCreateReleasePopup ] = React.useState(false);
    const [ data, setData ] = React.useState([]);

    const getReleaseData = async () => {
        let res = await getReleases();
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getReleases", res);
            return res.data;
        } else {
            return [];
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const result = await getReleaseData();
            setData(result);
        }


        fetchData().catch(error => {
            console.log("error", error);
        });
    }, []);

    const toggleCreateReleasePopup = () => {
        if (!createReleasePopup) {
            setCreateReleasePopup(true);
        } else {
            setCreateReleasePopup(false);
        }
    }

    return (
        <div>
            <div className="adminStatHeader alignCenter">
                <div className="adminStatHeaderLabel">Releases</div>
                <div className="adminButton absoluteRight">
                    <Button type="primary" className="cardBtnNew" onClick={toggleCreateReleasePopup.bind(this)}>Add release</Button>
                </div>
                {
                    createReleasePopup ?
                        <CreateReleaseComponent closePopup={toggleCreateReleasePopup.bind(this)}></CreateReleaseComponent>
                        : null
                }
            </div>
            <div className="adminReleasesBody">
                <AdminReleaseCalenderComponent releases={data}></AdminReleaseCalenderComponent>
            </div>
        </div>
    )
}