import React, { useEffect } from 'react';
import './AdminStatistics.css';
import {
    DropdownComponent
} from '../Dropdown';
import {
    AdminOrderHistoryComponent
} from '../../components';
import UpIcon from '../../icons/v2/UpIcon.svg';
import DownIcon from '../../icons/v2/DownIcon.svg';
import { getAllCheckoutsAdmin } from '../../utils/api';

export function AdminStatisticsComponent({
    allCheckouts,
    yearlyCheckouts,
    monthlyCheckouts,
    weeklyCheckouts,
    dailyCheckouts
}) {

    const currentDate = new Date();
    const filteredDate = `${(currentDate.getHours() < 10 ? '0' : '')}${currentDate.getHours()}:${(currentDate.getMinutes() < 10 ? '0' : '')}${currentDate.getMinutes()}`;

    const dropdownOption = [
        "All",
        "This Year",
        "This Month",
        "This Week",
        "Today"
    ];
    const dropdownMapping = {
        "All": "All",
        "This Year": "Year",
        "This Month": "Month",
        "This Week": "Week",
        "Today": "Day",
    }

    const [selectedRange, setselectedRange] = React.useState(dropdownOption[0]);
    const [data, setData] = React.useState({AllSuccess: [], AllFailure: [], YearSuccess: [], AllFailure: [], MonthSuccess: [], AllFailure: [], WeekSuccess: [], AllFailure: [], DaySuccess: [], AllFailure: []});
    //const [totalCheckoutCount, setTotalCheckoutCount] = React.useState(0);
    //const [totalCheckoutSuccessCount, setTotalCheckoutSuccessCount] = React.useState(0);
    //const [totalCheckoutFailureCount, setTotalCheckoutFailureCount] = React.useState(0);
    //const [orderStatistic, setOrderStatistic] = React.useState({All: [], Day: [], Month: [], Week: [], Year: []});

    const getAllCheckoutsAdminData = async () => {
        let res = await getAllCheckoutsAdmin();
        if (res !== null && res !== undefined && res.status === 200) {
            console.log("getCheckoutsProductData", res);
            return res.data;
        } else {
            return {AllSuccess: [], AllFailure: [], YearSuccess: [], AllFailure: [], MonthSuccess: [], AllFailure: [], WeekSuccess: [], AllFailure: [], DaySuccess: [], AllFailure: []};
        }
    }

    useEffect(() => {
    
        const fetchData = async () => {
            const result = await getAllCheckoutsAdminData();
            setData(result);
        }

        fetchData().catch(error => {
            console.log("error", error);
        });


        /*
        let totalCheckout = 0;
        let totalCheckoutSuccess = 0;
        let totalCheckoutFailed = 0;
        if (data !== []) {
            if (selectedRange === 'All') {
                totalCheckoutSuccess = data["AllSuccess"].length;
                totalCheckoutFailed = data["AllFailed"].length;
                totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
            } else if (selectedRange === 'This Year') {
                totalCheckoutSuccess = data["YearSuccess"].length;
                totalCheckoutFailed = data["YearFailed"].length;
                totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
            } else if (selectedRange === 'This Month') {
                totalCheckoutSuccess = data["MonthSuccess"].length;
                totalCheckoutFailed = data["MonthFailed"].length;
                totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
            } else if (selectedRange === 'This Week') {
                totalCheckoutSuccess = data["WeekSuccess"].length;
                totalCheckoutFailed = data["WeekFailure"].length;
                totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
            } else if (selectedRange === 'Today') {
                totalCheckoutSuccess = data["DaySuccess"].length;
                totalCheckoutFailed = data["DayFailure"].length;
                totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
            }

            const orderStatistics = {
                All: data["AllSuccess"],
                Day: data["YearSuccess"],
                Month: data["MonthSuccess"],
                Week: data["WeekSuccess"],
                Year: data["DaySuccess"],
            }
            setOrderStatistic(orderStatistics);
        } else {
            const orderStatistics = {
                All: [],
                Day: [],
                Month: [],
                Week: [],
                Year: [],
            }
            setOrderStatistic(orderStatistics);
        }
        setTotalCheckoutCount(totalCheckout);
        setTotalCheckoutSuccessCount(totalCheckoutSuccess);
        setTotalCheckoutFailureCount(totalCheckoutFailed);
        */
    }, []);


    const handleSetselectedRange = (data) => {
        console.log('handleSetselectedRange', data);
        setselectedRange(data);
    }


    //let totalCheckout = 0;
    //let totalCheckoutSuccess = 0;
    //let totalCheckoutFailed = 0;
    /*
    if (selectedRange === 'All') {
        totalCheckoutSuccess = data["AllSuccess"].length;
        totalCheckoutFailed = data["AllFailed"].length;
        totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
    } else if (selectedRange === 'This Year') {
        totalCheckoutSuccess = data["YearSuccess"].length;
        totalCheckoutFailed = data["YearFailed"].length;
        totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
    } else if (selectedRange === 'This Month') {
        totalCheckoutSuccess = data["MonthSuccess"].length;
        totalCheckoutFailed = data["MonthFailed"].length;
        totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
    } else if (selectedRange === 'This Week') {
        totalCheckoutSuccess = data["WeekSuccess"].length;
        totalCheckoutFailed = data["WeekFailure"].length;
        totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
    } else if (selectedRange === 'Today') {
        totalCheckoutSuccess = data["DaySuccess"].length;
        totalCheckoutFailed = data["DayFailure"].length;
        totalCheckout = totalCheckoutSuccess + totalCheckoutFailed;
    }
    */

    //console.log('AdminStatisticsComponent', allCheckouts);
    //console.log('AdminStatisticsComponent', yearlyCheckouts);
    //console.log('AdminStatisticsComponent', monthlyCheckouts);
    //console.log('AdminStatisticsComponent', weeklyCheckouts);
    //console.log('AdminStatisticsComponent', dailyCheckouts);

    /*
    const orderStatistics = {
        All: allCheckouts,
        Day: dailyCheckouts,
        Month: monthlyCheckouts,
        Week: weeklyCheckouts,
        Year: yearlyCheckouts,
    }
    */
    /*
     const orderStatistics = {
         All: data["AllSuccess"],
         Day: data["YearSuccess"],
         Month: data["MonthSuccess"],
         Week: data["WeekSuccess"],
         Year: data["DaySuccess"],
     }
     */


    return (
        <div>
            {/* <h1>STATISTICS</h1>
            <li>Daily: {dailyCheckouts.length}</li>
            <li>Weekly: {weeklyCheckouts.length}</li>
            <li>Monthly: {monthlyCheckouts.length}</li>
            <li>Yearly: {yearlyCheckouts.length}</li>
            <li>All: {allCheckouts.length}</li> */}
            <div className="adminStatHeader">
                <div className="adminStatHeaderLabel">Analytic</div>
                <div className="adminStatHeaderRight">
                    <div className="lastUpdateTime">{`Updated today at ${filteredDate}`}</div>
                    <div>
                        <DropdownComponent
                            options={dropdownOption}
                            selectedRange={selectedRange}
                            handleSetselectedRange={handleSetselectedRange}
                        />
                    </div>
                </div>
            </div>
            <div className="adminStatBody">
                <div className="adminStatBodyTop">
                    <div className="adminStatBodyTopInner">
                        <div className="adminItem">
                            <div className="adminItemTop">
                                <div className="adminItemAmount">
                                    {data["AllSuccess"].length + data["AllFailure"].length}
                                </div>
                                <div className="adminItemLabel">
                                    Total Checkout
                                </div>
                            </div>
                            <div className="adminItemBottom">
                                <div className="adminItemBottomAmount colorGreen"><img src={UpIcon} alt="UpIcon" />12 Items</div>
                                <div className="adminItemBottomLabel">(+1.5%) Last 7 Days</div>
                            </div>
                        </div>
                        <div className="adminItem">
                            <div className="adminItemTop">
                                <div className="adminItemAmount successCheckout">
                                    {data["AllSuccess"].length}
                                </div>
                                <div className="adminItemLabel">
                                    Success Checkout
                                </div>
                            </div>
                            <div className="adminItemBottom">
                                <div className="adminItemBottomAmount colorGreen"><img src={UpIcon} alt="UpIcon" />12 Items</div>
                                <div className="adminItemBottomLabel">(+1.5%) Last 7 Days</div>
                            </div>
                        </div>
                        <div className="adminItem">
                            <div className="adminItemTop">
                                <div className="adminItemAmount failedCheckout">
                                    {data["AllFailure"].length}
                                </div>
                                <div className="adminItemLabel">
                                    Failed Checkout
                                </div>
                            </div>
                            <div className="adminItemBottom">
                                <div className="adminItemBottomAmount colorRed"><img src={DownIcon} alt="DownIcon" />2 Items</div>
                                <div className="adminItemBottomLabel">(-1.5%) Last 7 Days</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="adminStatBodyBottom">
                    <AdminOrderHistoryComponent
                        checkoutData={data}
                    />
                </div>
            </div>
        </div>
    )
}