import React from 'react';
import CardInput from './CardInput';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, Checkbox } from 'antd';
import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import './CreatePayment.css';
import Close from '../../icons/v2/Close.svg';
import Success from '../../icons/v2/Success.svg';
import Failure from '../../icons/v2/Failure.svg';
import axios from 'axios';
import Stripe from '../../icons/v2/Stripe.svg';
import { getPlatformBackend, getPlatformFrontend } from '../../utils/platform';
import { postUpdateCustomerInfo } from '../../utils/api';
import { LogoLoaderComponent } from '..';

export function CreatePaymentComponent(
    props
) {

    const [loading, setLoading] = React.useState(false);
    const [isShowResult, setisShowResult] = React.useState(false);
    const [isSuccuess, setisSuccuess] = React.useState(false);
    const [resultTitle, setResultTitle] = React.useState('');
    const [resultDescription, setResultDescription] = React.useState('Error');
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);
    const [cardName, setCardName] = React.useState('');

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmitSub = async (event) => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (cardName === '') {
            setResultTitle("Oops, Something's Wrong");
            setResultDescription("Please enter your name on card");
            setisSuccuess(false);
            setisShowResult(true);
            return;
        }

        if (!acceptedTerms) {
            setResultTitle("Oops, Something's Wrong");
            setResultDescription("Please accept the terms and conditions");
            setisSuccuess(false);
            setisShowResult(true);
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: cardName
            }
        });

        console.log("Result", result);

        setLoading(true);
        setisShowResult(false);
        setisSuccuess(false);


        if (result.error) {
            console.log(result.error.message);
            setLoading(false);
            setResultTitle("Oops, Something's Wrong");
            setResultDescription(result.error.message);
            setisShowResult(true);
            setisSuccuess(false);

        } else {
            //add when marketplace
            /*
            if (type === "renewal") {
                res = await axios.post(getPlatformBackend() + '/api/stripe/createSubscriptionRenew', { "customerId": customerId, "payment_method": result.paymentMethod.id });
            } else if (type === "subscription") {
                res = await axios.post(getPlatformBackend() + '/api/stripe/createSubscription', { "customerId": customerId, "payment_method": result.paymentMethod.id });
            }
            */

            let res;

            try {
                res = await axios.post(getPlatformBackend() + '/api/stripe/createSubscription', { "customerId": props.customerId, "payment_method": result.paymentMethod.id, "acceptedTerms": acceptedTerms });
            } catch (err) {
                console.log(err);
                res = err.response;
                // Display the error message to the user
                //setErrorMessage('An error occurred while creating the subscription');
                setLoading(false);
                setResultTitle("Oops, Something's Wrong");
                if (res) {
                    setResultDescription(res.data.err[0].msg);
                }
                setisShowResult(true);
                setisSuccuess(false);
                return;
            }
            const { clientSecret, status } = res.data;

            console.log("RURURUR", res);

            if (status === 'requires_action') {
                stripe.confirmCardPayment(clientSecret).then(function (result) {
                    if (result.error) {
                        console.log('There was an issue!');
                        console.log(result.error);
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                        setLoading(false);
                        setResultTitle("Oops, Something's Wrong");
                        setResultDescription(result.error.message);
                        setisShowResult(true);
                        setisSuccuess(false);
                        //redirect to reset customer
                        window.location.href = getPlatformFrontend() + "/dashboard/subscribe";
                    } else {
                        console.log('You got the money!');
                        // Show a success message to your customer
                        setLoading(false);
                        setResultTitle("Your Payment was Successful! Redirecting...");
                        setResultDescription("Welcome to QBot 2.0");
                        setisShowResult(true);
                        setisSuccuess(true);
                        //close and redirect to dash
                        window.location.href = getPlatformFrontend() + "/dashboard";
                    }
                });
            } else if (status === 'requires_payment_method') {
                console.log('There was an issue!');

                setLoading(false);
                setResultTitle("Oops, Something's Wrong");
                setResultDescription("Your payment method couldnt be used.");
                setisShowResult(true);
                setisSuccuess(false);
                //redirect to reset customer
                //window.location.href = getPlatformFrontend() + "/dashboard/subscribe";
            } else if (status === 'succeeded') {
                console.log('You got the money!');
                setLoading(false);
                setResultTitle("Your Payment was Successful! Redirecting...");
                setResultDescription("Welcome to QBot 2.0");
                setisShowResult(true);
                setisSuccuess(true);
                //close and redirect to dash

                window.location.href = getPlatformFrontend() + "/dashboard";

            } else {
                console.log('There was an issue!');
                //how to handle requires_confirmation
                console.log("STATUSSSS", status);
                setLoading(false);
                setResultTitle("Oops, Something's Wrong");
                setResultDescription("Your payment method couldnt be used.");
                setisShowResult(true);
                setisSuccuess(false);
                //redirect to reset customer
                //window.location.href = getPlatformFrontend() + "/dashboard/subscribe";
            }

        }
    }

    const backToHome = () => {
        props.closeCardPopup();
    }

    const tryAgain = () => {
        setisShowResult(false);
    }

    const onChangeCheckbox = (e) => {
        setAcceptedTerms(e.target.checked);
    }

    const handleCardName = (e) => {
        setCardName(e.target.value);
    }

    let title = "subscription";
    let description = "200€ Upgrade fee + 50€"
    let description2 = "/ Month"
    let description3 = "(Total 250€ to start subscription, including taxes)"
    if (props.userType.toLowerCase() === "lifetime") {
        title = "payment"
        description = "200€ Upgrade fee"
        description2 = ""
        description3 = "(Total 200€, including taxes)"
    }

    return (
        <div className='popup'>
            {
                isShowResult ? (
                    <div className='popup_inner'>
                        {
                            isSuccuess ? (
                                <div>
                                    <div className="resultIconContainer">
                                        <img src={Success} alt="successIcon" className="resultIcon" />
                                    </div>
                                    <div className="resultTitle">
                                        {resultTitle}
                                    </div>
                                    <div className="resultDesc">
                                        {resultDescription}
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            className="backToBtn"
                                            onClick={backToHome.bind(this)}
                                        >
                                            Back To Home
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="resultIconContainer">
                                        <img src={Failure} alt="failIcon" className="resultIcon" />
                                    </div>
                                    <div className="resultTitle">
                                        {resultTitle}
                                    </div>
                                    <div className="resultDesc">
                                        {resultDescription}
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            className="backToBtn"
                                            onClick={tryAgain.bind(this)}
                                        >
                                            Try Again
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                ) : loading ? (
                    <LogoLoaderComponent></LogoLoaderComponent>
                ) : (
                    <div className='popup_inner'>
                        <img src={Close} alt="closeIcon" className="closeIcon" onClick={props.closeCardPopup} />
                        <div className="updateAddressTitle">
                            Finalise {title}
                        </div>
                        <div className="updateAddressTitle updateCardTitle">
                            {description}
                            <div className="updateCardSubTitle">{description2}</div>
                        </div>
                        <div className="updateAddressTitle updateCardSubTitle">
                            {description3}
                        </div>
                        <div className="updatePaymentRoot">
                            <div className="updatePaymentContent">
                                <div className="updateAddressColumn stripCardInputSection">
                                    <div className="updateAddressLabel">
                                        Card Info<span className="updateAddressLabelStar">*</span>
                                    </div>
                                    <CardInput />
                                </div>
                                <div className="updateAddressColumn">
                                    <div className="updateAddressLabel">
                                        Name on Card<span className="updateAddressLabelStar">*</span>
                                    </div>
                                    <input placeholder="Name" onChange={handleCardName} value={cardName} />
                                </div>
                                {/* <Button type="primary" className="submitButton" onClick={handleSubmitSub}>Renew Now</Button> */}
                                <div className="updateCardDesc">
                                    <Checkbox onChange={onChangeCheckbox}>I confirm that I have read and understand the <a href="/terms">Terms and Conditions</a> and the <a href="/cancellationpolicy">Cancellation Policy</a>, and I agree to be bound by them.</Checkbox>
                                </div>
                                <input type="submit" className="submitButton" value="Submit" onClick={handleSubmitSub} />
                                <div className="updateCardFooter">
                                    <img src={Stripe} alt="stripIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
}