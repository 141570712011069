import React from 'react';
import { useEffect } from 'react';
import './LicenseList.css';
import { Input, Spin, Space, Button, Table } from 'antd';
import Search from '../../icons/search.svg';
import DetailGrey from '../../icons/v2/DetailGrey.svg';
import DetailBlue from '../../icons/v2/DetailBlue.svg';
import ArrowBack from '../../icons/v2/ArrowBack.svg';
import { SearchOutlined } from '@ant-design/icons';

export function LicenseListComponent({
    licenseList,
    closeClients
}) {

    const columns = [
        {
            title: '',
            dataIndex: 'avatar',
            render: (value) => {
                return (
                    <div className="avatarDetailsImg">
                        <img
                            src={value}
                            alt='avatar'
                        />
                    </div>
                )
            }
        },
        {
            title: 'LICENSE',
            dataIndex: 'license',
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
        },
        {
            title: 'TINFO',
            dataIndex: 'taskinfo',
        },/*
        {
            title: 'EMAIL',
            dataIndex: 'email',
        },*/
        {
            title: 'DISCORD',
            dataIndex: 'discord',
        },
        {
            title: 'RUNNING',
            dataIndex: 'running',
            render: (value) => {
                return (
                    <div>
                        {value === "true" ?
                            <div className="colorTrue">{value}</div>
                            : <div className="colorFalse">{value}</div>}
                    </div>
                )
            }
        },
        {
            title: 'TIMESTAMP',
            dataIndex: 'lastlogout',
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => {
                return (
                    <div>
                        <Button type="primary" style={{ padding: 2, margin: 4 }}>
                            Disable
                        </Button>
                        <Button type="primary" style={{ padding: 2, margin: 4 }}>
                            Pause
                        </Button>
                        <Button type="primary" style={{ padding: 2, margin: 4 }} onClick={() => handleClose(record)}>
                            Close
                        </Button>
                    </div>

                );
            },
        },
        /*
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => {
                console.log('tableColumn x', text, record);
                return (
                    <Button type="primary">
                        Pause
                    </Button>
                );
            },
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => {
                console.log('tableColumn x', text, record);
                return (
                    <Button type="primary">
                        Stop
                    </Button>
                );
            },
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => {
                console.log('tableColumn x', text, record);
                return (
                    <Button type="primary">
                        Delete
                    </Button>
                );
            },
        },
        */
    ];

    const data = [];
    licenseList.forEach((ele, index) => {

        let running = "true";
        if (ele.ws_id === null) {
            running = "false";
        }
        let taskinfo = "{}";
        if (ele.task_info !== null) {
            taskinfo = ele.task_info;
        }

        let lastlogout;
        if (ele.ping_time !== null) {
            let intt = parseInt(ele.ping_time);
            let date = new Date(intt);
            lastlogout = date.toLocaleString();
        }

        let expiry;
        if (ele.license_expiry !== null) {
            expiry = new Date(ele.license_expiry).toLocaleString();
        }
        
        let avatar;
        if (ele.avatar === null) {
            avatar = "https://cdn.discordapp.com/embed/avatars/1.png";
        } else {
            avatar = "https://cdn.discordapp.com/avatars/" + ele.discord_id + "/" + ele.avatar + ".png";
        }
        
        data.push({
            key: index,
            avatar: avatar,
            license: ele.license_key,
            type: ele.license_type,
            taskinfo: taskinfo,
            //email: ele.email,
            discord: ele.discord_id,
            running: running,
            lastlogout: lastlogout
        });
    });
    /*
    for (let i = 0; i < 26; i++) {
        data.push({
            key: i,
            license: `1111-1111-1111-1111`,
            type: 'Renewal',
            expiry: "10/04/2019",
            email: "blabal@gmail.com",
            discord: "333372675860987910",
            running: "true",
            lastlogout: "10/04/2019 132131",
        });
    }
    */

    const handleClose = (record) => {
        closeClients(record.license);
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    return (
        <div className="infoContainer">
            <div className="orderStatisticTop paymentHeader">
                <div className="infoHeader orderStatisticHeader">
                    Licenses
                </div>
                <div className="orderSearchHolder">
                    <Input
                        className="orderSearch"
                        placeholder="Search license, discordId, Email"
                        prefix={<img
                            src={Search}
                            alt="chartIcon"
                            className=""
                        />}
                    />
                </div>
            </div>
            <div className="orderHistoryTable">
                <Table columns={columns} dataSource={data} onChange={onChange} />
            </div>
        </div>
    )
}