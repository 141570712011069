import React from 'react';
import { Button } from 'antd';
import './AdminLogs.css';

export function AdminLogsComponent({

}) {
    return (
        <div>
            <div className="adminStatHeader">
                <div className="adminStatHeaderLabel">Logs</div>
            </div>
        </div>
    )
}